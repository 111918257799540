import {
  apiInterface,
  reqInterface,
  resInterface,
} from "@/api/interfaces";
import AbstractApi from "../../abstract";
import {
  IAddress,
  ICultivarGroups,
  IAgriculturalMachinery,
  ICultivarUsageTypes,
  ILightZone,
  IPaginatedResult,
  IVarieties,
  IVariety,
  IChemistryList,
  IExpense,
  IRate,
} from "@/api/interfaces/responses";
import { getCookie } from "@/utils/cookie";
import { TOKEN_KEYS } from "@/const";
import { AxiosResponse } from "axios";
import { IRegionReq } from "@/api/interfaces/requests";

class RegulatoryInfoApi
  implements apiInterface.IRegulatoryInfo {
  ctx: AbstractApi;

  constructor(ctx: AbstractApi) {
    this.ctx = ctx;
  }

  getPlotDirection = async (params) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IPlotDirection>
    >("/regulatory_info/plot_direction/", params);

    return res.data;
  };

  getVariantList = async (params) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IVariant>
    >("/regulatory_info/variant/", params);

    return res.data;
  };

  getCountriesList = async (params) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.ICountry>
    >("/regulatory_info/country/", params);

    return res.data;
  };

  getCountryById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.ICountry,
      resInterface.ICountry
    >(`/regulatory_info/country/${id}`);

    return res.data;
  };

  postCountry = async (params) => {
    const res = await this.ctx.post<
      reqInterface.ICountry,
      resInterface.ICountry
    >("/regulatory_info/country/", params);

    return res.data;
  };

  deleteCountry = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.ICountry>
    >(`/regulatory_info/country/${id}/`);
    return res.data;
  };

  updateCountry = async (id, params) => {
    const res = await this.ctx.patch<
      reqInterface.ICountry,
      Promise<resInterface.ICountry>
    >(`/regulatory_info/country/${id}/`, params);
    return res.data;
  };

  postAddress = async (params) => {
    const res = await this.ctx.post<IAddress, IAddress>(
      "/regulatory_info/address/",
      params,
    );

    return res.data;
  };

  postLightZone = async (params) => {
    const res = await this.ctx.post<ILightZone, ILightZone>(
      "/regulatory_info/light_zone/",
      params,
    );

    return res.data;
  };

  updateAddress = async (id, params) => {
    const res = await this.ctx.patch<IAddress, IAddress>(
      `/regulatory_info/address/${id}/`,
      params,
    );

    return res.data;
  };

  deleteAddress = async (id: number) => {
    const res = await this.ctx.delete<
      reqInterface.IList,
      Promise<IAddress>
    >(`/regulatory_info/address/${id}/`);
    return res.data;
  };

  deleteLightZone = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<ILightZone>
    >(`/regulatory_info/light_zone/${id}/`);
    return res.data;
  };

  updateLightZone = async (id, params) => {
    const res = await this.ctx.patch<
      unknown,
      Promise<ILightZone>
    >(`/regulatory_info/light_zone/${id}/`, params);
    return res.data;
  };

  getAllAddresList = async (params) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IAddress>
    >("/regulatory_info/address/", params);

    return res.data;
  };

  getAddresById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IAddress
    >(`/regulatory_info/address/${id}/`);

    return res.data;
  };

  getAllLightZoneList = async (params) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.ILightZone>
    >("/regulatory_info/light_zone/", params);

    return res.data;
  };

  getLightZoneById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.ILightZone
    >(`/regulatory_info/light_zone/${id}`);

    return res.data;
  };

  getVarietyList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IVariety>
    >("/regulatory_info/variety/", params);

    return res.data;
  };


  postVariety = async (
    params: reqInterface.IVarietyListReq,
  ): Promise<IVariety> => {
    const res = await this.ctx.post<
      reqInterface.IVarietyListReq,
      IVariety
    >("/regulatory_info/variety/", params);
    return res.data;
  };

  patchVarietyByID = async (
    id: number,
    params: reqInterface.TVarietyReq,
  ): Promise<IVariety> => {
    const res = await this.ctx.patch<
      reqInterface.TVarietyReq,
      IVariety
    >(`/regulatory_info/variety/${id}/`, params);
    return res.data;
  };

  // Сельхозтехника

  getAgrCultMachineryList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IAgriculturalMachinery>
    >("/regulatory_info/agricultural_machinery/", params);

    return res.data;
  };
  getAgrCultMachineryById = async (id: number) => {
    const res = await this.ctx.get<reqInterface.IList, resInterface.IAgriculturalMachinery>(
      `/regulatory_info/agricultural_machinery/${id}/`
    );
    return res.data;
  };
  postAgrCultMachinery = async (
    params: reqInterface.IAgriculturalMachineryReq,
  ): Promise<resInterface.IAgriculturalMachinery> => {
    const res = await this.ctx.post<
      reqInterface.IAgriculturalMachineryReq,
      resInterface.IAgriculturalMachinery
    >("/regulatory_info/agricultural_machinery/", params);
    return res.data;
  };
  patchAgrCultMachineryById = async (
    id: number,
    params: reqInterface.IAgriculturalMachineryReq,
  ): Promise<IAgriculturalMachinery> => {
    const res = await this.ctx.patch<
      reqInterface.IAgriculturalMachineryReq,
      IAgriculturalMachinery
    >(`/regulatory_info/agricultural_machinery/${id}/`, params);
    return res.data;
  };
  deleteAgrCultMachinery = async (id: number) => {
    const res = await this.ctx.delete<unknown, Promise<IAgriculturalMachinery>>(
      `/regulatory_info/agricultural_machinery/${id}/`
    );
    return res.data;
  };

  addAgrMachineryFileById = async (
    id: string | number,
    params: FormData,
  ) => {
    const res = await this.ctx.post<
      FormData,
      resInterface.IFileAgriculturalMachinery
    >(`/regulatory_info/agricultural_machinery/${id}/add_file/`, params);
    return res.data;
  };
  deleteAgrMachineryFile = async (
    agrMachineryId: string | number,
    fileId: string | number,
  ) => {
    await this.ctx.delete(
      `/regulatory_info/agricultural_machinery/${agrMachineryId}/delete_file/${fileId}/`,
    );
  };

  getMachineryTypeList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IMachineryType>
    >("/regulatory_info/machinery_type/", params);

    return res.data;
  };

  // Cultivar Usage Type 

  getCultivarUsageTypesList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.ICultivarUsageTypes>
    >("/regulatory_info/cultivar_usage_type/", params);

    return res.data;
  };
  getCultivarUsageTypeById = async (id: number) => {
    const res = await this.ctx.get<reqInterface.IList, resInterface.ICultivarUsageTypes>(
      `/regulatory_info/cultivar_usage_type/${id}`
    );
    return res.data;
  };
  postCultivarUsageType = async (
    params: reqInterface.ICultivarUsageTypeReq,
  ): Promise<ICultivarUsageTypes> => {
    const res = await this.ctx.post<
      reqInterface.ICultivarUsageTypeReq,
      ICultivarUsageTypes
    >("/regulatory_info/cultivar_usage_type/", params);
    return res.data;
  };

  patchCultivarUsageType = async (
    id: number,
    params: reqInterface.ICultivarUsageTypeReq,
  ): Promise<ICultivarUsageTypes> => {
    const res = await this.ctx.patch<
      reqInterface.ICultivarUsageTypeReq,
      ICultivarUsageTypes
    >(`/regulatory_info/cultivar_usage_type/${id}/`, params);
    return res.data;
  };

  deleteCultivarUsageType = async (id: number) => {
    const res = await this.ctx.delete<unknown, Promise<ICultivarUsageTypes>>(
      `/regulatory_info/cultivar_usage_type/${id}/`
    );
    return res.data;
  };

  // Expenses

  getExpenses = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IExpense>
    >("/regulatory_info/expense/", params);

    return res.data;
  };
  getExpenseById = async (id: number) => {
    const res = await this.ctx.get<reqInterface.IList, resInterface.IExpense>(
      `/regulatory_info/expense/${id}/`
    );
    return res.data;
  };
  postExpense = async (
    params: reqInterface.IExpenseReq,
  ): Promise<IExpense> => {
    const res = await this.ctx.post<
      reqInterface.IExpenseReq,
      IExpense
    >("/regulatory_info/expense/", params);
    return res.data;
  };

  patchExpense = async (
    id: number,
    params: reqInterface.IExpenseReq,
  ): Promise<IExpense> => {
    const res = await this.ctx.patch<
      reqInterface.IExpenseReq,
      IExpense
    >(`/regulatory_info/expense/${id}/`, params);
    return res.data;
  };

  deleteExpense = async (id: number) => {
    const res = await this.ctx.delete<unknown, Promise<IExpense>>(
      `/regulatory_info/expense/${id}/`
    );
    return res.data;
  };

  // Rate

  getRates = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IRate>
    >("/regulatory_info/rate/", params);

    return res.data;
  };
  getRateById = async (id: number) => {
    const res = await this.ctx.get<reqInterface.IList, resInterface.IRate>(
      `/regulatory_info/rate/${id}/`
    );
    return res.data;
  };
  postRate = async (
    params: reqInterface.IRateReq,
  ): Promise<IRate> => {
    const res = await this.ctx.post<
      reqInterface.IRateReq,
      IRate
    >("/regulatory_info/rate/", params);
    return res.data;
  };

  patchRate = async (
    id: number,
    params: reqInterface.IRateReq,
  ): Promise<IRate> => {
    const res = await this.ctx.patch<
      reqInterface.IRateReq,
      IRate
    >(`/regulatory_info/rate/${id}/`, params);
    return res.data;
  };

  deleteRate = async (id: number) => {
    const res = await this.ctx.delete<unknown, Promise<IRate>>(
      `/regulatory_info/rate/${id}/`
    );
    return res.data;
  };

  // Cultivar Group
  getCultivarGroups = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.ICultivarGroups>
    >("/regulatory_info/cultivar_group/", params);

    return res.data;
  };
  getCultivarGroupById = async (id: number) => {
    const res = await this.ctx.get<reqInterface.IList, resInterface.ICultivarGroups>(
      `/regulatory_info/cultivar_group/${id}/`
    );
    return res.data;
  };
  postCultivarGroup = async (
    params: reqInterface.ICultivarGroupReq
  ): Promise<ICultivarGroups> => {
    const res = await this.ctx.post<
      reqInterface.ICultivarGroupReq,
      ICultivarGroups
    >("/regulatory_info/cultivar_group/", params);
    return res.data;
  };
  patchCultivarGroup = async (
    id: number,
    params: reqInterface.ICultivarGroupReq,
  ): Promise<ICultivarGroups> => {
    const res = await this.ctx.patch<
      reqInterface.ICultivarGroupReq,
      ICultivarGroups
    >(`/regulatory_info/cultivar_group/${id}/`, params);
    return res.data;
  };
  deleteCultivarGroup = async (id: number) => {
    const res = await this.ctx.delete<unknown, Promise<ICultivarGroups>>(
      `/regulatory_info/cultivar_group/${id}/`
    );
    return res.data;
  };

  // Химия

  getChemistriesList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IChemistryList>
    >("/regulatory_info/chemistry/", params);

    return res.data;
  };
  getChemistryById = async (id: number) => {
    const res = await this.ctx.get<reqInterface.IList, resInterface.IChemistryList>(
      `/regulatory_info/chemistry/${id}/`
    );
    return res.data;
  };
  postChemistry = async (
    params: reqInterface.IChemistryReq
  ): Promise<IChemistryList> => {
    const res = await this.ctx.post<
      reqInterface.IChemistryReq,
      IChemistryList
    >("/regulatory_info/chemistry/", params);
    return res.data;
  };
  patchChemistryById = async (
    id: number,
    params: reqInterface.IChemistryReq,
  ): Promise<IChemistryList> => {
    const res = await this.ctx.patch<
      reqInterface.IChemistryReq,
      IChemistryList
    >(`/regulatory_info/chemistry/${id}/`, params);
    return res.data;
  };
  deleteChemistry = async (id: number) => {
    const res = await this.ctx.delete<unknown, Promise<IChemistryList>>(
      `/regulatory_info/chemistry/${id}/`
    );
    return res.data;
  };

  //Сорта

  getVarietiesList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IVarieties>
    >("/regulatory_info/variety/", params);

    return res.data;
  };

  getVarietyById = async (id: number) => {
    const res = await this.ctx.get<reqInterface.IList, resInterface.IVarieties>(
      `/regulatory_info/variety/${id}`
    );
    return res.data;
  };

  deleteVariety = async (id: number) => {
    const res = await this.ctx.delete<unknown, Promise<IVarieties>>(
      `/regulatory_info/variety/${id}/`
    );
    return res.data;
  };

  postVarietiesItem = async (
    params: reqInterface.IVarietiesReq,
  ): Promise<IVarieties> => {
    const res = await this.ctx.post<
      reqInterface.IVarietiesReq,
      IVarieties
    >("/regulatory_info/variety/", params);
    return res.data;
  };

  patchVarietiesById = async (
    id: number,
    params: reqInterface.IVarietiesReq,
  ): Promise<IVarieties> => {
    const res = await this.ctx.patch<
      reqInterface.IVarietiesReq,
      IVarieties
    >(`/regulatory_info/variety/${id}/`, params);
    return res.data;
  };

  getCultivarListForVariety = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.ICultivarForVariety>
    >("/regulatory_info/cultivar/list_for_varieties/", params);

    return res.data
  }

  getDepartmentList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IDepartment>
    >("/regulatory_info/department/", params);
    return res.data;
  };

  getDepartmentsList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IDepartments>
    >("/regulatory_info/department/", params);
    return res.data;
  };

  getDepartmentById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IDepartments
    >(`/regulatory_info/department/${id}`);
    return res.data;
  };

  postDepartment = async (params: reqInterface.IDepartmentsReq) => {
    const res = await this.ctx.post<
      unknown,
      Promise<resInterface.IDepartments>
    >(`/regulatory_info/department/`, params);
    return res.data;
  };

  patchDepartmentById = async (
    id: number,
    params: reqInterface.IDepartmentsReq,
  ) => {
    const res = await this.ctx.patch<
      unknown,
      Promise<resInterface.IDepartments>
    >(`/regulatory_info/department/${id}/`, params);
    return res.data;
  };

  deleteDepartment = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IDepartments>
    >(`/regulatory_info/department/${id}/`);
    return res.data;
  };

  getCultivarList = async (
    params: reqInterface.IDiseaseList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IDiseaseList,
      IPaginatedResult<resInterface.ICultivar>
    >("/regulatory_info/cultivar/", params);
    return res.data;
  };

  getCultivarById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.ICultivarHandbook
    >(`/regulatory_info/cultivar/${id}`);

    return res.data;
  };

  postCultivar = async (params: reqInterface.ICultivar) => {
    const res = await this.ctx.post<
      unknown,
      Promise<resInterface.ICultivarHandbook>
    >(`/regulatory_info/cultivar/`, params);
    return res.data;
  };

  patchCultivarByID = async (
    id: number,
    params: reqInterface.ICultivar,
  ) => {
    const res = await this.ctx.patch<
      unknown,
      Promise<resInterface.ICultivarHandbook>
    >(`/regulatory_info/cultivar/${id}/`, params);
    return res.data;
  };

  deleteCultivar = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.ICultivar>
    >(`/regulatory_info/cultivar/${id}/`);
    return res.data;
  };

  getAddressList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IAddress>
    >("/regulatory_info/address/", params);
    return res.data;
  };

  getPlotList = async (
    params: reqInterface.IGetPlotList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IGetPlotList,
      IPaginatedResult<resInterface.IPlot>
    >("/regulatory_info/plot/", params);
    return res.data;
  };

  getPlotById = async (
    id: number | string,
  ): Promise<resInterface.IPlot> => {
    const res = await this.ctx.get<
      reqInterface.TVarietyReq,
      resInterface.IPlot
    >(`/regulatory_info/plot/${id}/`);
    return res.data;
  };

  patchPlotById = async (
    id: number,
    params: Partial<resInterface.IPlot>,
  ) => {
    const res = await this.ctx.patch<
      Partial<resInterface.IPlot>,
      resInterface.IPlot
    >(`/regulatory_info/plot/${id}/`, params);
    return res.data;
  };

  getPlanBranchesList = async (
    params: reqInterface.IGetPlanBranchesList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IGetPlanBranchesList,
      IPaginatedResult<resInterface.IBranchItem>
    >("/regulatory_info/branch/", params);
    return res.data;
  };

  postBranch = async (params: reqInterface.IBranchReq) => {
    const res = await this.ctx.post<
      reqInterface.IBranchReq,
      Promise<resInterface.IBranchItem>
    >("/regulatory_info/branch/", params);
    return res.data;
  };

  patchBranchById = async (
    id: number,
    params: Partial<reqInterface.IBranchReq>,
  ) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.IBranchReq>,
      Promise<resInterface.IBranchItem>
    >(`/regulatory_info/branch/${id}/`, params);
    return res.data;
  };

  getBranchById = async (
    id: number,
  ): Promise<resInterface.IBranchItem> => {
    const res = await this.ctx.get<
      never,
      resInterface.IBranchItem
    >(`/regulatory_info/branch/${id}/`);
    return res.data;
  };

  deleteBranchById = async (
    id: number,
  ): Promise<AxiosResponse> => {
    const res = await this.ctx.delete<
      unknown,
      AxiosResponse
    >(`/regulatory_info/branch/${id}/`);
    return res.data;
  };

  getRegionList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IGetPlanBranchesList,
      IPaginatedResult<resInterface.IRegionItem>
    >("/regulatory_info/region/", params);
    return res.data;
  };

  postRegion = async (params: reqInterface.IRegionReq) => {
    const res = await this.ctx.post<
      reqInterface.IRegionReq,
      resInterface.IRegionItem
    >("/regulatory_info/region/", params);
    return res.data;
  };

  getRegionByID = async (id: number) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IRegionItem
    >(`/regulatory_info/region/${id}`);
    return res.data;
  };

  patchRegionByID = async (
    id: number | string,
    params: {
      name: string;
      number: number;
    },
  ) => {
    const res = await this.ctx.patch<
      IRegionReq,
      resInterface.IRegionItem
    >(`/regulatory_info/region/${id}/`, params);
    return res.data;
  };

  deleteRegionByID = async (id: string | number) => {
    const res = await this.ctx.delete<
      unknown,
      AxiosResponse
    >(`/regulatory_info/region/${id}`, {});
    return res.data;
  };

  getDiseaseList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IDiseaseList>
    >("/regulatory_info/disease/", params);
    return res.data;
  };

  getDiseaseById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IDisease,
      resInterface.IDisease
    >(`/regulatory_info/disease/${id}`);

    return res.data;
  };

  createDisease = async (params: reqInterface.IDisease) => {
    const res = await this.ctx.post<
      reqInterface.IDisease,
      Promise<resInterface.IDisease>
    >(`/regulatory_info/disease/`, params);
    return res.data;
  };

  updateDisease = async (
    id: number,
    params: reqInterface.IDisease,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IDisease,
      Promise<resInterface.IDisease>
    >(`/regulatory_info/disease/${id}/`, params);
    return res.data;
  };

  deleteDisease = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IDisease>
    >(`/regulatory_info/disease/${id}/`);
    return res.data;
  };



  checkUniqueVarietyName = async (params: {
    cultivar: number;
    name: string;
  }) => {
    const res = await this.ctx.post<
      {
        cultivar: number;
        name: string;
      },
      {
        unique: boolean;
      }
    >("/regulatory_info/variety/check_name/", params);
    return res.data;
  };

  getChemistryList = async (
    params: reqInterface.IListChemistry,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IListChemistry,
      IPaginatedResult<resInterface.IChemistry>
    >("/regulatory_info/chemistry/", params);
    return res.data;
  };

  getPostList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IPost>
    >("/regulatory_info/post/", params);
    return res.data;
  };

  getPostById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IPost
    >(`/regulatory_info/post/${id}`);

    return res.data;
  };

  createPost = async (params: reqInterface.IPost) => {
    const res = await this.ctx.post<
      reqInterface.IPost,
      Promise<resInterface.IPost>
    >(`/regulatory_info/post/`, params);
    return res.data;
  };

  updatePost = async (
    id: number,
    params: reqInterface.IPost,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IPost,
      Promise<resInterface.IPost>
    >(`/regulatory_info/post/${id}/`, params);
    return res.data;
  };

  deletePost = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IPost>
    >(`/regulatory_info/post/${id}/`);
    return res.data;
  };

  getExpenseList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IExpenseList>
    >("/regulatory_info/expense/", params);
    return res.data;
  };

  getDestinationList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IDestination>
    >("/regulatory_info/destination/", params);
    return res.data;
  };

  getOrderCultivarList = async (
    params: reqInterface.IOrderCultivarList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IOrderCultivarList,
      IPaginatedResult<resInterface.IOrderCultivar>
    >("/regulatory_info/order_cultivar/", params);
    return res.data;
  };

  getBranchList = async (params) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IBranchList>
    >("/regulatory_info/branch/", params);

    return res.data;
  };

  getEmployeeList = async (params) => {
    const res = await this.ctx.get<
      reqInterface.IEmployeeList,
      IPaginatedResult<resInterface.IEmployeeList>
    >("/regulatory_info/employee/", params);

    return res.data;
  };

  getEmployeeById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IEmployee,
      resInterface.IEmployee
    >(`/regulatory_info/employee/${id}`);

    return res.data;
  };

  postEmployee = async (params) => {
    const res = await this.ctx.post<
      reqInterface.IEmployee,
      resInterface.IEmployee
    >("/regulatory_info/employee/", params);

    return res.data;
  };

  deleteEmployee = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IEmployee>
    >(`/regulatory_info/employee/${id}/`);
    return res.data;
  };

  updateEmployee = async (id, params) => {
    const res = await this.ctx.patch<
      reqInterface.IEmployee,
      Promise<resInterface.IEmployee>
    >(`/regulatory_info/employee/${id}/`, params);
    return res.data;
  };

  getOrderCultivarById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IOrderCultivar
    >(`/regulatory_info/order_cultivar/${id}`);

    return res.data;
  };

  getOrderList = async (
    params: reqInterface.IOrderList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IOrder>
    >("/regulatory_info/order/", params);
    return res.data;
  };

  getOrderById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IOrder,
      resInterface.IOrder
    >(`/regulatory_info/order/${id}`);

    return res.data;
  };

  postOrder = async (params) => {
    const res = await this.ctx.post<
      reqInterface.IOrder,
      resInterface.IOrder
    >("/regulatory_info/order/", params);

    return res.data;
  };

  deleteOrder = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IOrder>
    >(`/regulatory_info/order/${id}/`);
    return res.data;
  };

  updateOrder = async (id, params) => {
    const res = await this.ctx.patch<
      reqInterface.IOrder,
      Promise<resInterface.IOrder>
    >(`/regulatory_info/order/${id}/`, params);
    return res.data;
  };

  orderAddFile = async (id, params) => {
    const res = await this.ctx.post<
      FormData,
      resInterface.IOrderFile
    >(`/regulatory_info/order/${id}/add_file/`, params);

    return res.data;
  };

  orderDeleteFile = async (id: number, file_id: number) => {
    const res = await this.ctx.delete<
      unknown,
      resInterface.IOrder
    >(
      `/regulatory_info/order/${id}/delete_file/${file_id}/`,
    );
    return res.data;
  };

  postOrderCultivar = async (
    params: reqInterface.IOrderCultivarParams,
  ): Promise<resInterface.IOrderCultivar> => {
    const res = await this.ctx.post<
      reqInterface.IOrderCultivarParams,
      resInterface.IOrderCultivar
    >(
      "/regulatory_info/order_cultivar/",

      params,
    );
    return res.data;
  };

  updateOrderCultivar = async (
    id: number,
    params: reqInterface.IOrderCultivarParams,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IOrderCultivarParams,
      Promise<resInterface.IOrderCultivar>
    >(`/regulatory_info/order_cultivar/${id}/`, params);
    return res.data;
  };

  deleteOrderCultivar = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IOrderCultivar>
    >(`/regulatory_info/order_cultivar/${id}/`);
    return res.data;
  };

  getOrderPlotList = async (
    params: reqInterface.IOrderPlotList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IOrderPlotList,
      IPaginatedResult<resInterface.IOrderPlot>
    >("/regulatory_info/order_cultivar_plot/", params);
    return res.data;
  };

  getOrderPlotById = async (
    id: number,
  ): Promise<resInterface.IOrderPlot> => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IOrderPlot
    >(`/regulatory_info/order_cultivar_plot/${id}/`);
    return res.data;
  };

  postOrderPlot = async (
    params: reqInterface.IOrderPlotParams,
  ): Promise<resInterface.IOrderPlot> => {
    const res = await this.ctx.post<
      reqInterface.IOrderCultivarParams,
      resInterface.IOrderPlot
    >("/regulatory_info/order_cultivar_plot/", params);
    return res.data;
  };

  updateOrderPlot = async (
    id: number,
    params: Partial<reqInterface.IOrderPlotParams>,
  ) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.IOrderPlotParams>,
      Promise<resInterface.IOrderPlot>
    >(
      `/regulatory_info/order_cultivar_plot/${id}/`,
      params,
    );
    return res.data;
  };

  getCultivarGroupList = async (
    params: reqInterface.ICultivarGroupListParams,
  ) => {
    const res = await this.ctx.get<
      reqInterface.ICultivarGroupList,
      IPaginatedResult<resInterface.IOrderCultivarGroup>
    >("/regulatory_info/cultivar_group/", params);
    return res.data;
  };

  deleteOrderPlot = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IOrderPlot>
    >(`/regulatory_info/order_cultivar_plot/${id}/`);
    return res.data;
  };

  getCultivarUsageTypeList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.ICultivarUsageType>
    >("/regulatory_info/cultivar_usage_type/", params);
    return res.data;
  };

  getOrderCultivarUsageType = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IOrderCultivarUsageType[]
    >(`/regulatory_info/order_cultivar/${id}/usage_types/`);

    return res.data;
  };

  getOrderCultivarGroups = async (
    id: number,
    params: reqInterface.ICultivarGroupList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.ICultivarGroupList,
      resInterface.IOrderCultivarGroups[]
    >(
      `/regulatory_info/order_cultivar/${id}/cultivar_groups/`,
      params,
    );

    return res.data;
  };

  // Количество в разнарядках
  getQuantityDistributionList = async (
    params: reqInterface.IQuantityDistributionList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IQuantityDistributionList,
      IPaginatedResult<resInterface.IQDList>
    >(
      "/regulatory_info/quantity_distribution_list/",
      params,
    );
    return res.data;
  };

  getQuantityDistributionItem = async (
    id: number | string,
  ): Promise<resInterface.IQDList> => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IQDList
    >(`/regulatory_info/quantity_distribution_list/${id}/`);
    return res.data;
  };

  postQuantityDistributionItem = async (
    params: reqInterface.IQDPostOrPatch,
  ) => {
    const res = await this.ctx.post<
      reqInterface.IQDPostOrPatch,
      IPaginatedResult<resInterface.IQDList>
    >(
      "/regulatory_info/quantity_distribution_list/",
      params,
    );
    return res.data;
  };

  patchQuantityDistributionItem = async (
    id: number | string,
    params: reqInterface.IQDPostOrPatch,
  ): Promise<resInterface.IQDList> => {
    const res = await this.ctx.patch<
      reqInterface.IQDPostOrPatch,
      resInterface.IQDList
    >(
      `/regulatory_info/quantity_distribution_list/${id}/`,
      params,
    );
    return res.data;
  };

  deleteQuantityDistributionItem = async (
    id: number | string,
  ) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IQDList>
    >(`/regulatory_info/quantity_distribution_list/${id}/`);
    return res.data;
  };

  getUnitList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IUnit>
    >("/regulatory_info/unit/", params);
    return res.data;
  };
  getUnitById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IUnit,
      resInterface.IUnit
    >(`/regulatory_info/unit/${id}/`);

    return res.data;
  };

  postUnit = async (params) => {
    const res = await this.ctx.post<
      reqInterface.IUnit,
      resInterface.IUnit
    >("/regulatory_info/unit/", params);

    return res.data;
  };

  deleteUnit = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IUnit>
    >(`/regulatory_info/unit/${id}/`);
    return res.data;
  };

  updateUnit = async (id, params) => {
    const res = await this.ctx.patch<
      reqInterface.IUnit,
      Promise<resInterface.IUnit>
    >(`/regulatory_info/unit/${id}/`, params);
    return res.data;
  };

  // Фазы вегетации
  getVegetationStagesList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IVegetationStage>
    >("/regulatory_info/vegetation_stage/", params);
    return res.data;
  };

  getVegetationStageById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IVegetationStage
    >(`/regulatory_info/vegetation_stage/${id}/`);
    return res.data;
  };

  postVegetationStage = async (params) => {
    const res = await this.ctx.post<
      reqInterface.IVegetationStageReq,
      resInterface.IVegetationStage
    >("/regulatory_info/vegetation_stage/", params);
    return res.data;
  };

  patchVegetationStage = async (
    id: number | string,
    params,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IVegetationStageReq,
      Promise<resInterface.IVegetationStage>
    >(`/regulatory_info/vegetation_stage/${id}/`, params);
    return res.data;
  };

  deleteVegetationStage = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IVegetationStage>
    >(`/regulatory_info/vegetation_stage/${id}/`);
    return res.data;
  };


  // Vegetation Causes

  getVegetationCauses = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IVegetationCauses>
    >("/regulatory_info/vegetation_causes/", params);
    return res.data;
  };

  getVegetationCauseById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IVegetationCauses
    >(`/regulatory_info/vegetation_causes/${id}/`);
    return res.data;
  };

  postVegetationCauses = async (params) => {
    const res = await this.ctx.post<
      reqInterface.IVegetationCausesReq,
      resInterface.IVegetationCauses
    >("/regulatory_info/vegetation_causes/", params);
    return res.data;
  };

  patchVegetationCauses = async (
    id: number | string,
    params,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IVegetationCausesReq,
      Promise<resInterface.IVegetationCauses>
    >(`/regulatory_info/vegetation_causes/${id}/`, params);
    return res.data;
  };

  deleteVegetationCauses = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IVegetationCauses>
    >(`/regulatory_info/vegetation_causes/${id}/`);
    return res.data;
  };

  // Plots
  getPlotsForRegulatoryInfo = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IPlotList>
    >("/regulatory_info/plot/plots_for_regulatory_info/", params);
    return res.data;
  };

  getPlotItemForRegulatoryInfo = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IPlotList
    >(`/regulatory_info/plot/${id}/plot_for_regulatory_info_by_id/`);
    return res.data;
  };

  patchPlotItemForRegulatoryInfo = async (id: number, params: Partial<reqInterface.IPlotReq>) => {
    const res = await this.ctx.patch<
      reqInterface.IPlotReq,
      resInterface.IPlotList
    >(`/regulatory_info/plot/${id}/plot_for_regulatory_info_by_id/`, params);
    return res.data;
  };

  deletePlotItemForRegulatoryInfo = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      void
    >(`/regulatory_info/plot/${id}/plot_for_regulatory_info_by_id/`);
    return res.data;
  };

  getPlotsList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IPlotList>
    >("/regulatory_info/plot/", params);
    return res.data;
  };

  getPlotItemById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IPlotList
    >(`/regulatory_info/plot/${id}/`);
    return res.data;
  };

  postPlot = async (params) => {
    const res = await this.ctx.post<
      reqInterface.IPlotReq,
      resInterface.IPlotList
    >("/regulatory_info/plot/", params);
    return res.data;
  };

  patchPlot = async (
    id: number | string,
    params,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IPlotReq,
      Promise<resInterface.IPlotList>
    >(`/regulatory_info/plot/${id}/`, params);
    return res.data;
  };

  deletePlot = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IPlotList>
    >(`/regulatory_info/plot/${id}/`);
    return res.data;
  };


  // Репродукции

  getReproductionList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IReproductionListItem>
    >("/regulatory_info/reproduction/", params);
    return res.data;
  };

  getReproductionById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IReproductionParams,
      resInterface.IReproductionListItem
    >(`/regulatory_info/reproduction/${id}/`);
    return res.data;
  };

  postReproduction = async (params) => {
    const res = await this.ctx.post<
      reqInterface.IReproductionParams,
      resInterface.IReproductionListItem
    >("/regulatory_info/reproduction/", params);
    return res.data;
  };

  patchReproduction = async (
    id: number | string,
    params,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IReproductionParams,
      Promise<resInterface.IReproductionListItem>
    >(`/regulatory_info/reproduction/${id}/`, params);
    return res.data;
  };

  deleteReproduction = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IReproductionListItem>
    >(`/regulatory_info/reproduction/${id}/`);
    return res.data;
  };

  // materialTypes
  getMaterialTypeList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IMaterialType>
    >("/regulatory_info/material_type/", params);
    return res.data;
  };

  getMaterialTypeById = async (id: number | string) => {
    const res = await this.ctx.get<
      resInterface.IMaterialType,
      resInterface.IMaterialType
    >(`/regulatory_info/material_type/${id}/`);
    return res.data;
  };

  postMaterialType = async (params: { name: string }) => {
    const res = await this.ctx.post<
      unknown,
      IPaginatedResult<resInterface.IMaterialType>
    >(`/regulatory_info/material_type/`, params);
    return res.data;
  };

  patchMaterialType = async (
    id: number | string,
    params: { name: string },
  ) => {
    const res = await this.ctx.patch<
      unknown,
      IPaginatedResult<resInterface.IMaterialType>
    >(`/regulatory_info/material_type/${id}/`, params);
    return res.data;
  };

  deleteMaterialType = async (id: number | string) => {
    const res = await this.ctx.delete<
      unknown,
      IPaginatedResult<resInterface.IMaterialType>
    >(`/regulatory_info/material_type/${id}/`);
    return res.data;
  };

  getRemarkList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IRemarkHandbook>
    >("/registration/remark/", params);
    return res.data;
  };

  getRemarkById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IRemarkHandbook,
      resInterface.IRemarkHandbook
    >(`/registration/remark/${id}`);

    return res.data;
  };

  postRemark = async (
    params: reqInterface.IRemarkHandbook,
  ) => {
    const res = await this.ctx.post<
      unknown,
      Promise<resInterface.IRemarkHandbook>
    >(`/registration/remark/`, params);
    return res.data;
  };

  patchRemarkByID = async (
    id: number,
    params: reqInterface.IRemarkHandbook,
  ) => {
    const res = await this.ctx.patch<
      unknown,
      Promise<resInterface.IRemarkHandbook>
    >(`/registration/remark/${id}/`, params);
    return res.data;
  };

  getRegionMain = async () => {
    const res = await this.ctx.get<unknown, resInterface.IRegionMain>('/regulatory_info/region/main/')
    return res.data
  }

  deleteRemark = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IRemarkHandbook>
    >(`/registration/remark/${id}/`);
    return res.data;
  };

  downloadOrder = async (
    id: number | string,
    name: string,
  ) => {
    const accessToken = getCookie(
      TOKEN_KEYS.AUTH_TOKEN_KEY,
    );
    const url = `${this.ctx.MAIN_URL}/regulatory_info/order/${id}/download_order/`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();

        // Создаем URL для скачивания файла
        const fileUrl = URL.createObjectURL(blob);

        // Создаем ссылку на элемент <a> для скачивания файла
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = `${name}.docx`; // Устанавливаем имя файла для скачивания
        document.body.appendChild(link);

        // Кликаем на ссылку для скачивания файла
        link.click();

        // Удаляем ссылку на элемент <a>
        document.body.removeChild(link);

        // Освобождаем URL
        URL.revokeObjectURL(fileUrl);
        return true;
      } else {
        console.error(
          `Ошибка при скачивании файла. Статус: ${response.status}`,
        );
        return false;
      }
    } catch (error) {
      console.error("Произошла ошибка:", error);
      return false;
    }
  };

  getQuantityDistributionCultivarList = async (
    params: Partial<reqInterface.IList>,
  ) => {
    const res = await this.ctx.get<
      Partial<reqInterface.IList>,
      IPaginatedResult<resInterface.IQDCultivarListItem>
    >(
      "/regulatory_info/cultivar/list_for_quantity_distribution_list/",
      params,
    );
    return res.data;
  };

  copyOrder = async (from: number, to: number) => {
    const res = await this.ctx.post<
      {
        source_order: number;
        target_order: number;
      },
      AxiosResponse
    >("/regulatory_info/order/copy_order/", {
      source_order: from,
      target_order: to,
    });
    return res.data;
  };

  // VarietyIndicator

  getVarietyIndicator = async (
    params: reqInterface.IList & {
      variety: number;
      indicator_type: string;
    },
  ) => {
    const res = await this.ctx.get<
      reqInterface.IVarietyIndicatorReq,
      IPaginatedResult<resInterface.IVarietyIndicatorRes>
    >("/regulatory_info/variety_indicator/", params);
    return res.data;
  }

  postVarietyIndicator = async (
    params: reqInterface.IList & {
      variety: number;
      indicator_type: string;
      variant_indicator_methodology: number;
    },
  ) => {
    const res = await this.ctx.post<
      reqInterface.IVarietyIndicatorReq,
      IPaginatedResult<resInterface.IVarietyIndicatorRes>
    >("/regulatory_info/variety_indicator/", params);
    return res.data;
  }

  patchVarietyIndicatorById = async (
    params: reqInterface.IList & {
      variety?: number;
      indicator_type?: string;
      variant_indicator_methodology?: number;
    },
    id: number,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IVarietyIndicatorReq,
      IPaginatedResult<resInterface.IVarietyIndicatorRes>
    >(`/regulatory_info/variety_indicator/${id}/`, params);
    return res.data;
  }

  deleteVarietyIndicator = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<AxiosResponse>
    >(`/regulatory_info/variety_indicator/${id}/`);
    return res.data;
  };

  // IndicatorMethodology

  getIndicatorMethodologyList = async (
    params: reqInterface.IList & {
      id: number;
    },
  ) => {
    const res = await this.ctx.get<
      reqInterface.IIndicatorMethodologyParams,
      IPaginatedResult<resInterface.IIndicator>
    >("/regulatory_info/indicator_methodology/", params);
    return res.data;
  };

  postIndicatorMethodology = async (
    params: reqInterface.IIndicatorMethodologyCreateParams,
  ): Promise<resInterface.IIndicator> => {
    const res = await this.ctx.post<
      reqInterface.IOrderCultivarParams,
      resInterface.IIndicator
    >("/regulatory_info/indicator_methodology/", params);
    return res.data;
  };

  deleteIndicatorMethodology = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IIndicator>
    >(`/regulatory_info/indicator_methodology/${id}/`);
    return res.data;
  };

  patchIndicatorMethodology = async (
    params: reqInterface.IIndicatorMethodologyCreateParams,
  ): Promise<resInterface.IIndicator> => {
    const res = await this.ctx.patch<
      reqInterface.IOrderCultivarParams,
      resInterface.IIndicator
    >("/regulatory_info/indicator_methodology/", params);
    return res.data;
  };

  // регион
  getDistrictList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IDistrict>
    >("/regulatory_info/district/", params);
    return res.data;
  };

  //регионы РФ

  getDistrictsList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IDistrictRF>
    >("/regulatory_info/district/", params);
    return res.data;
  };

  getDistrictById = async (id: number | string) => {
    const res = await this.ctx.get<
      unknown,
      Promise<resInterface.IDistrictRF>
    >(`/regulatory_info/district/${id}/`);
    return res.data;
  };

  postDistrict = async (
    params: reqInterface.IDistrictRFReq,
  ) => {
    const res = await this.ctx.post<
      unknown,
      Promise<resInterface.IDistrictRF>
    >(`/regulatory_info/district/`, params);
    return res.data;
  };

  patchDistrictById = async (
    id: number | string,
    params: reqInterface.IDistrictRFReq,
  ) => {
    const res = await this.ctx.patch<
      unknown,
      Promise<resInterface.IDistrictRF>
    >(`/regulatory_info/district/${id}/`, params);
    return res.data;
  };

  deleteDistrict = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IDistrictRF>
    >(`/regulatory_info/district/${id}/`);
    return res.data;
  };

  getLightZoneForDistricts = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.ILightZone>
    >("/regulatory_info/light_zone/list_for_districts/", params);
    return res.data;
  }

  // признаки
  getIndicatorsList = async (
    params: reqInterface.IList & {
      type_trial: "oos" | "hp";
    },
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IIndicatorsList>
    >("/regulatory_info/indicator/", params);
    return res.data;
  };

  getIndicatorsItem = async (id: number | string) => {
    const res = await this.ctx.get<
      unknown,
      Promise<resInterface.IIndicatorsList>
    >(`/regulatory_info/indicator/${id}/`);
    return res.data;
  };

  postIndicatorsItem = async (
    params: reqInterface.ICreateIndicator,
  ) => {
    const res = await this.ctx.post<
      unknown,
      Promise<reqInterface.ICreateIndicator>
    >(`/regulatory_info/indicator/`, params);
    return res.data;
  };

  patchIndicatorsItem = async (
    id: number | string,
    params: reqInterface.IUpdateIndicator,
  ) => {
    const res = await this.ctx.patch<
      unknown,
      Promise<reqInterface.IUpdateIndicator>
    >(`/regulatory_info/indicator/${id}/`, params);
    return res.data;
  };

  deleteIndicatorsItem = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IIndicator>
    >(`/regulatory_info/indicator/${id}/`);
    return res.data;
  };
  // варианты признаков
  getIndicatorsVariantById = async (params: {
    id: number;
  }) => {
    const res = await this.ctx.get<
      unknown,
      Promise<
        IPaginatedResult<resInterface.IIndicatorsVariantById>
      >
    >(`/regulatory_info/variant/`, params);
    return res.data;
  };

  patchIndicatorsVariantById = async (
    id: string | number,
    params: reqInterface.IIndicatorVariant,
  ) => {
    const res = await this.ctx.patch<
      unknown,
      Promise<
        IPaginatedResult<resInterface.IIndicatorsVariantById>
      >
    >(`/regulatory_info/variant/${id}/`, params);
    return res.data;
  };

  postIndicatorsVariantById = async (
    params: reqInterface.IIndicatorVariant,
  ) => {
    const res = await this.ctx.post<
      unknown,
      Promise<
        IPaginatedResult<resInterface.IIndicatorsVariantById>
      >
    >(`/regulatory_info/variant/`, params);
    return res.data;
  };
  deleteIndicatorsVariantById = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<
        IPaginatedResult<resInterface.IIndicatorsVariantById>
      >
    >(`/regulatory_info/variant/${id}/`);
    return res.data;
  };

  deleteIndicatorsVariantMethodologyById = async (
    id: number,
  ) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<
        IPaginatedResult<resInterface.IIndicatorsVariantById>
      >
    >(
      `/regulatory_info/variant_indicator_methodology/${id}/`,
    );
    return res.data;
  };

  // методики
  getMethodologyList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      unknown,
      Promise<IPaginatedResult<resInterface.IMethList>>
    >(`/regulatory_info/methodology/`, params);
    return res.data;
  };

  getMethodologyItem = async (id: number | string) => {
    const res = await this.ctx.get<
      unknown,
      Promise<resInterface.IMethDetail>
    >(`/regulatory_info/methodology/${id}/`);
    return res.data;
  };

  getVariantIndicatorMethodology = async (
    params: reqInterface.IList & {
      id: number;
    },
  ) => {
    const res = await this.ctx.get<
      unknown,
      Promise<IPaginatedResult<resInterface.IVariantMeth>>
    >(
      `/regulatory_info/variant_indicator_methodology/`,
      params,
    );
    return res.data;
  };

  postMethodologyItem = async (
    params: reqInterface.IPostMeth,
  ) => {
    const res = await this.ctx.post<
      unknown,
      Promise<IPaginatedResult<resInterface.IMethList>>
    >(`/regulatory_info/methodology/`, params);
    return res.data;
  };

  patchMethodologyItem = async (
    id: string | number,
    params: reqInterface.IPostMeth,
  ) => {
    const res = await this.ctx.patch<
      unknown,
      Promise<IPaginatedResult<resInterface.IMethList>>
    >(`/regulatory_info/methodology/${id}/`, params);
    return res.data;
  };

  deleteMethodologyItem = async (id: string | number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<IPaginatedResult<resInterface.IMethList>>
    >(`/regulatory_info/methodology/${id}/`);
    return res.data;
  };

  postIndicatorMethodologyItem = async (
    params: reqInterface.IPostIndicatorMethodologyParams[],
  ) => {
    const res = await this.ctx.post<
      unknown,
      Promise<IPaginatedResult<resInterface.IMethList>>
    >(
      `/regulatory_info/indicator_methodology/bulk_create/`,
      params,
    );
    return res.data;
  };

  patchIndicatorMethodologyItem = async (
    id: string | number,
    params: reqInterface.IPostIndicatorMethodologyParams[],
  ) => {
    const res = await this.ctx.patch<
      unknown,
      Promise<IPaginatedResult<resInterface.IMethList>>
    >(
      `/regulatory_info/indicator_methodology/${id}/`,
      params,
    );
    return res.data;
  };

  postVariantIndicatorMethodology = async (
    params: reqInterface.IPostVariantIndicatorMethodologyParams,
  ) => {
    const res = await this.ctx.post<
      unknown,
      Promise<IPaginatedResult<resInterface.IVariantMeth>>
    >(
      `/regulatory_info/variant_indicator_methodology/bulk_create/`,
      params,
    );
    return res.data;
  };

  patchVariantIndicatorMethodology = async (
    id: number | string,
    params: reqInterface.IPostVariantIndicatorMethodologyParams,
  ) => {
    const res = await this.ctx.patch<
      unknown,
      Promise<IPaginatedResult<resInterface.IVariantMeth>>
    >(
      `/regulatory_info/variant_indicator_methodology/${id}/`,
      params,
    );
    return res.data;
  };

  getVegetationCausesList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      Promise<
        IPaginatedResult<resInterface.IVegetationCause>
      >
    >(`/regulatory_info/vegetation_causes/`, params);
    return res.data;
  };

  methodologyAddFile = async (id, params) => {
    const res = await this.ctx.post<
      FormData,
      resInterface.IOrderFile
    >(
      `/regulatory_info/methodology/${id}/add_file/`,
      params,
    );

    return res.data;
  };

  methodologyDeleteFile = async (id, file_id) => {
    const res = await this.ctx.delete<
      FormData,
      resInterface.IOrderFile
    >(
      `/regulatory_info/methodology/${id}/delete_file/${file_id}`,
    );

    return res.data;
  };

  getVegetationStageList = async (
    params: reqInterface.IVegetationStageListReq,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IVegetationStageListReq,
      Promise<
        IPaginatedResult<resInterface.IVegetationStage>
      >
    >(`/regulatory_info/vegetation_stage/`, params);
    return res.data;
  };
  getBranchMainById = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.IBranchMain>(`/regulatory_info/branch/main/${id}/`)
    return res.data
  }

  getBranchMainTrialsById = async (id: number | string, params: reqInterface.IBranchMainReq) => {
    const res = await this.ctx.get<reqInterface.IBranchMainReq, resInterface.IBranchMainTrials>(`/regulatory_info/branch/main_trials/${id}/`, params)
    return res.data
  }

  getBranchMainProductionById = async (id: number | string, params: reqInterface.IBranchMainReq) => {
    const res = await this.ctx.get<reqInterface.IBranchMainReq, resInterface.IBranchMainProduction>(`/regulatory_info/branch/main_production/${id}/`, params)
    return res.data
  }

  getPlotMainById = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.IPlotMain>(`/regulatory_info/plot/main/${id}/`)
    return res.data
  }

  getPlotMainTrialsById = async (id: number | string, params: reqInterface.IBranchMainReq) => {
    const res = await this.ctx.get<reqInterface.IBranchMainReq, resInterface.IBranchMainTrials>(`/regulatory_info/plot/main_trials/${id}/`, params)
    return res.data
  }

  getPlotMainProductionById = async (id: number | string, params: reqInterface.IBranchMainReq) => {
    const res = await this.ctx.get<reqInterface.IBranchMainReq, resInterface.IBranchMainProduction>(`/regulatory_info/plot/main_production/${id}/`, params)
    return res.data
  }

  getRateList = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<reqInterface.IList, IPaginatedResult<resInterface.IRate>>('/regulatory_info/rate/', params)
    return res.data
  }

  getSurveyList = async (params: reqInterface.ISurveyListReq) => {
    const res = await this.ctx.get<reqInterface.ISurveyListReq, IPaginatedResult<resInterface.ISurvey>>('/registration/survey/', params)
    return res.data
  }

  postSurvey = async (params: reqInterface.IRegistrationSurveyReq) => {
    const res = await this.ctx.post<reqInterface.IRegistrationSurveyReq, resInterface.ISurvey>('/registration/survey/', params)
    return res.data
  }

  patchSurveyById = async (id: number | string, params: reqInterface.IRegistrationSurveyReq) => {
    const res = await this.ctx.patch<reqInterface.IRegistrationSurveyReq, resInterface.ISurvey>(`/registration/survey/${id}/`, params)
    return res.data
  }
  deleteSurveyById = async (id: number | string) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(`/registration/survey/${id}/`)
    return res.data
  }

  getWorkType = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<unknown, IPaginatedResult<resInterface.IWorkType>>(
      `/regulatory_info/worktype/`,
      params
    )
    return res.data;
  }

  downloadMainPageInfo = async (params: reqInterface.DownloadMainPageInfoReq) => {
    const res = await this.ctx.post<reqInterface.DownloadMainPageInfoReq, Blob>("/regulatory_info/region/download_main_page_info/", params, {
      responseType: "blob"
    })
    return res.data
  }
}

export default RegulatoryInfoApi;
