import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { IProps, TForm, validationSchema } from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import IndicatorForm from "./components/IndicatorForm";
import { mapFormToPatch, mapFormToPost } from "./mappings";

/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const AddIndicator: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const baseInitialValues = {
    name: undefined,
    type_trial: "oos",
    variants: [
      {
        id: null,
        index: null,
        severity: "",
      },    
    ],
    isEdit: modalProps?.isEdit, // валидация отличается при редактировании, нужно для schema
    non_field_errors: undefined,
  };

  const { indicatorId, initialValues } = modalProps;
  const { api, toastStore, swrStore } = useStores();

  const mutateDetail = indicatorId
    ? swrStore.mutators[
        SWR_KEYS.getIndicatorListItem(indicatorId)
      ]
    : null;

  const mutateList =
    swrStore.mutators[SWR_KEYS.getIndicatorList()];

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    const promise = indicatorId
      ? api.regulatoryInfo.patchIndicatorsItem(
          indicatorId,
          mapFormToPatch(values),
        )
      : api.regulatoryInfo.postIndicatorsItem(
          mapFormToPost(values),
        );

    promise
      .then(() => {
        mutateDetail && mutateDetail();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors.non_field_errors) {
          const errorMessage = errorToString(errors);
          helpers.setFieldError('non_field_errors', errorMessage)
        }
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        mutateList && mutateList();
      });
  };

  const values = initialValues
    ? {
        ...baseInitialValues,
        ...initialValues,
      }
    : baseInitialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <IndicatorForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default AddIndicator;
