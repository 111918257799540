import yup from "@/utils/yup";
import { VARIANT_MODAL } from "@/apps/Modals/const";

export const FIELDS_NAME = {
  APPLICANTS: "applicants", //заявители
  REPRESENTATIVES: "representative", //представители
  AUTHORS: "authors", //авторы
  CENSUS_TAKER: "census_taker", // переписчик
  PAYMENT_TYPE: "payment_type",
  VARIETY: "variety", //сорт
  CULTIVAR: "cultivar", // культура
  IS_RESIDENT: "is_resident", //тип заявителя
  BENEFIT: "benefit", //льгота
  ORIGINATORS: "originators", //оригинаторы
  REMARKS: "remarks", //замечания
  DUTIES: "duties", //пошлины
  REGISTRATION_DATE: "registration_date", //дата регистрации

  SURVEYS: "surveys", // Описание-Анкеты
  INDICATOR_METHODOLOGY: "indicator_methodology", // Признак методики
  SEVERITY: "severity", // Степень выраженности - Значение признака

  SURVEYS_OOS: "surveys_oos", // методолгия
  INDICATOR: "indicator", // Степень выраженности
} as const;

export const BENEFIT_TYPES = {
  WITHOUT_BENEFIT: 'without_benefit', //без льгот
  BENEFIT_50: 'benefit_50', // Льгота 50%
  BENEFIT_20: 'benefit_20' // Льгота 20%
}

export const subjectsSchema = yup.object().shape({
  [FIELDS_NAME.APPLICANTS]: yup.mixed().required("Выберите заявителей"),
  [FIELDS_NAME.AUTHORS]: yup.mixed().required("Выберите авторов"),
  [FIELDS_NAME.CENSUS_TAKER]: yup.mixed().required("Выберите переписчика"),
});

export const dataSchema = yup.object().shape({
  [FIELDS_NAME.PAYMENT_TYPE]: yup.string().oneOf(["budget", "paid"]).required(),
  [FIELDS_NAME.VARIETY]: yup.mixed().required("Выберите сорт"),
});

export const STEP_LABELS = {
  SUBJECTS: "Субъекты",
  DATA: "Данные заявки",
  DOCUMENTS: "Документы",
  PHOTOGRAPHY: "Фотографии",
  INDICATORS: "Анкета и описание"
};

export const SUBJECT_FIELDS = {


  
  [FIELDS_NAME.APPLICANTS]: {
    label: "Заявители",
    name: FIELDS_NAME.APPLICANTS,
    required: false,
    modalVariant: VARIANT_MODAL.APPLICANTS,
    isMulti: true,
  },
  [FIELDS_NAME.AUTHORS]: {
    label: "Авторы",
    name: FIELDS_NAME.AUTHORS,
    required: false,
    modalVariant: VARIANT_MODAL.AUTHORS,
    isMulti: true,
  },
  [FIELDS_NAME.CENSUS_TAKER]: {
    label: "Переписчик",
    name: FIELDS_NAME.CENSUS_TAKER,
    required: false,
    modalVariant: VARIANT_MODAL.CENSUS_TAKER,
    isMulti: false,
  },
  [FIELDS_NAME.ORIGINATORS]: {
    label: "Оригинаторы",
    name: FIELDS_NAME.ORIGINATORS,
    required: false,
    modalVariant: VARIANT_MODAL.ORIGINATORS,
    isMulti: true
  }
};
export const PATENT_SUBJECT_FIELDS = [
  SUBJECT_FIELDS[FIELDS_NAME.APPLICANTS],
  SUBJECT_FIELDS[FIELDS_NAME.AUTHORS],
  SUBJECT_FIELDS[FIELDS_NAME.CENSUS_TAKER],
];
export const APPROVAL_SUBJECT_FIELDS = [
  SUBJECT_FIELDS[FIELDS_NAME.APPLICANTS],
  SUBJECT_FIELDS[FIELDS_NAME.ORIGINATORS],
  SUBJECT_FIELDS[FIELDS_NAME.AUTHORS],
  SUBJECT_FIELDS[FIELDS_NAME.CENSUS_TAKER],
];
export const STATEMENT_FILES_TYPE = {
  PHOTO: "photo", //фото
  APPLICATION_FORM: "application_form", //заявление 300
  QUESTIONNAIRE: "questionnaire", //анкета
  DESCRIPTION: "description", //описание
  PARENTAL_LINE_QUESTIONNAIRE: "parental_line_questionnaire", // Анкета на родительские линии
  RIGHT_OF_FILING: "right_of_filing", //Документ, подтверждающий право на подачи заявки
  CERTIFICATE_OF_STABILITY: "certificate_of_stability", //Справки на устойчивость
  LETTER_OF_GUARANTEE: "letter_of_guarantee", //Гарантийное письмо
  NOT_CHOISE: "not_choice",
};

export const DOCUMENT_FIELDS = [
  {
    name: STATEMENT_FILES_TYPE.APPLICATION_FORM,
    title: "Заявление"
  },
  {
    name: STATEMENT_FILES_TYPE.QUESTIONNAIRE,
    title: "Анкета"
  },
  {
    name: STATEMENT_FILES_TYPE.DESCRIPTION,
    title: "Описание"
  },
  {
    name: STATEMENT_FILES_TYPE.PARENTAL_LINE_QUESTIONNAIRE,
    title: "Анкеты на родительские линии"
  },
  {
    name: STATEMENT_FILES_TYPE.RIGHT_OF_FILING,
    title: "Документ, подтверждающий право на подачу заявки"
  },
  {
    name: STATEMENT_FILES_TYPE.CERTIFICATE_OF_STABILITY,
    title: "Справки об устойчивости к вредоносным объектам"
  },
  {
    name: STATEMENT_FILES_TYPE.LETTER_OF_GUARANTEE,
    title: "Гарантийное письмо"
  },
  {
    name: STATEMENT_FILES_TYPE.NOT_CHOISE,
    title: "Прочие документы"
  },
];

export const DOCUMENT_FILE_FIELDS = {
  [STATEMENT_FILES_TYPE.APPLICATION_FORM]: {},
  [STATEMENT_FILES_TYPE.QUESTIONNAIRE]: {},
  [STATEMENT_FILES_TYPE.DESCRIPTION]: {},
  [STATEMENT_FILES_TYPE.PARENTAL_LINE_QUESTIONNAIRE]: {},
  [STATEMENT_FILES_TYPE.RIGHT_OF_FILING]: {},
  [STATEMENT_FILES_TYPE.CERTIFICATE_OF_STABILITY]: {},
  [STATEMENT_FILES_TYPE.LETTER_OF_GUARANTEE]: {},
  [STATEMENT_FILES_TYPE.NOT_CHOISE]: {},
}