import React from "react";
import { FastField, Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField, } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IFormik, UNFORMITY_VALUES } from "../const";
import {
  IndicatorMethodologyAutocomplete
} from "@/components/Autocompletes";
import IndicatorVariantMethodologyAutocomplete
  from "@/components/Autocompletes/IndicatorVariantMethodologyAutocomplete";
import { IIndicator } from "@/api/interfaces/responses";
import {
  IIndicatorMethodologyCreateParams
} from "@/api/interfaces/requests";

interface IProps {
  handleClose: () => void;
  q?: IIndicatorMethodologyCreateParams
}

const SelectIndicatorForm: React.FC<IProps> = ({
  handleClose,
  q
}) => {
  const formik = useFormikContext<IFormik>();

  const handleChangeIndicator = (payload: IIndicator) => {
    void formik.setFieldValue(FIELDS_NAME.INDICATOR, payload);
    void formik.setFieldValue(FIELDS_NAME.INDICATOR_VARIANT, "");
  };

  const handleClearIndicator = () => {
    void formik.setFieldValue(FIELDS_NAME.INDICATOR, "");
    void formik.setFieldValue(FIELDS_NAME.INDICATOR_VARIANT, "");
  };

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <IndicatorMethodologyAutocomplete
          name={FIELDS_NAME.INDICATOR}
          handleChange={handleChangeIndicator}
          onClear={handleClearIndicator} q={q} required />
        <IndicatorVariantMethodologyAutocomplete
          name={FIELDS_NAME.INDICATOR_VARIANT}
          q={{ id: formik.values[FIELDS_NAME.INDICATOR]?.id }}
          disabled={!formik.values[FIELDS_NAME.INDICATOR]}
          required />
        <FastField name={FIELDS_NAME.OFF_TYPE_PLANTS_NUMBER}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                type="text"
                label={"Число нетипичных растений"}
                fullWidth
                size="small"
              />
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </FastField>
        <FastField name={FIELDS_NAME.UNFORMITY}>
          {({ field, meta }) => (
            <FormControl>
              <FormLabel>Однородность:</FormLabel>
              <RadioGroup {...field}
                onChange={formik.handleChange}>
                <Stack direction="row" spacing={1}>
                  {UNFORMITY_VALUES.map((el) => {
                    return (
                      <FormControlLabel
                        key={el.id}
                        value={el.value}
                        control={
                          <Radio color="secondary" />
                        }
                        label={el.label}
                      />
                    );
                  })}
                </Stack>
              </RadioGroup>
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </FastField>
      </Stack>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default SelectIndicatorForm;
