import yup from "@/utils/yup";
import { IAgriculturalLabourLocation } from "@/api/interfaces/responses";

// Коордианты для тестирования
// const testCoords = [
//   [-0.03570556640625, 0.039138790901432],
//   [0.047035217285156, 0.037078854833759],
//   [0.0054931640625, -0.00995635981319],
// ];

export const FIELDS_NAME = {
  NAME: "name",
  COLOUR: "colour",
  BRANCH: "branch",
  PLOT: "plot",
  REGION: "region",
  COORDINATES: "coordinates",
  TOTAL_AREA: "total_area",
  AGRICULTURAL_LAND: "agricultural_land",
  ARABLE_LAND: "arable_land"
} as const;

export const ENUM_FILD_TO_RU_NAME = {
  name: "Название",
  colour: "Цвет",
  branch: "Филиал",
  plot: "ГСУ",
  region: "Регион",
  coordinates: "Список координат",
  total_area: "Площадь, га",
  agricultural_land: "С/х земли, га",
  arable_land: "Пашни, га"
};

const EMPTY_COORDS = [];

export const initialValues = {
  [FIELDS_NAME.COORDINATES]: EMPTY_COORDS,
  [FIELDS_NAME.BRANCH]: null,
  [FIELDS_NAME.REGION]: null,
  [FIELDS_NAME.PLOT]: null,
  [FIELDS_NAME.NAME]: "",
  [FIELDS_NAME.COLOUR]: "",
  [FIELDS_NAME.TOTAL_AREA]: "",
  [FIELDS_NAME.AGRICULTURAL_LAND]: "",
  [FIELDS_NAME.ARABLE_LAND]: ""
};

export interface IValue {
  id: number;
  label: string;
}

export interface IFormik {
  [FIELDS_NAME.COORDINATES]: number[][] | string[][];
  [FIELDS_NAME.BRANCH]: IValue | null;
  [FIELDS_NAME.REGION]: IValue | null;
  [FIELDS_NAME.PLOT]: IValue | null;
  [FIELDS_NAME.NAME]: string;
  [FIELDS_NAME.COLOUR]: string;
  [FIELDS_NAME.TOTAL_AREA]: null | number | string;
  [FIELDS_NAME.AGRICULTURAL_LAND]: number | string;
  [FIELDS_NAME.ARABLE_LAND]: number | string;
}

export const serializeValues = (
  values: IAgriculturalLabourLocation | undefined
) => ({
  name: values?.name || "",
  colour: values?.colour || "",
  region: values?.plot
    ? {
        id: values.plot.branch.region.id,
        label: values.plot.branch.region.name
      }
    : null,
  branch: values?.plot
    ? {
        id: values.plot.branch.id,
        label: values.plot.branch.name
      }
    : null,
  plot: values?.plot
    ? {
        id: values.plot.id,
        label: values.plot.name
      }
    : null,
  coordinates: values?.map_object?.coordinates?.[0]
    ? values.map_object.coordinates[0].slice(0, -1).map((el) => [el[1], el[0]])
    : EMPTY_COORDS,
  agricultural_land: values?.agricultural_land,
  arable_land: values?.arable_land
});

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.NAME]: yup.string().required(),
  [FIELDS_NAME.COLOUR]: yup.string().required(),
  [FIELDS_NAME.BRANCH]: yup.mixed().required(),
  [FIELDS_NAME.REGION]: yup.mixed().required(),
  [FIELDS_NAME.PLOT]: yup.mixed().required(),
  [FIELDS_NAME.COORDINATES]: yup
    .array()
    .of(yup.tuple([yup.number().required(), yup.number().required()]))
    .test("minLength", "Добавьте минимум 3 координаты", (value) => {
      return !(value && value.length > 0 && value.length < 3);
    }),
  [FIELDS_NAME.TOTAL_AREA]: yup.number(),
  [FIELDS_NAME.AGRICULTURAL_LAND]: yup.number(),
  [FIELDS_NAME.ARABLE_LAND]: yup.number()
});
