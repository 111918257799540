import React, { useEffect, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { SWR_KEYS } from "@/const";
import { resInterface } from "@/api/interfaces";
import theme from "@/theme";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

export const DistrictCell = (
  _: string,
  cell: resInterface.IPlotList,
): JSX.Element => {
  const { district } = cell;
  return (
    <Box>
      <Typography fontSize={14}>
        {district?.name ?? "-"}
      </Typography>
    </Box>
  );
};

export const STATUS_LABEL = {
  active: "Действующий",
  closed: "Закрытый",
} as const;

export const STATUS = {
  ACTIVE: "active",
  CLOSED: "closed",
} as const;

export const STATUS_ARRAY = [
  {
    name: STATUS.ACTIVE,
    label: STATUS_LABEL.active,
    textColor: theme.palette.green.main,
    backgroundColor: theme.palette.blackAndWhite.grayLight
  },
  {
    name: STATUS.CLOSED,
    label: STATUS_LABEL.closed,
    textColor: theme.palette.blackAndWhite.black,
    backgroundColor: theme.palette.blackAndWhite.grayLight
  },
] as const;

export const StatusCell = (status: any) => {
  const decoratedType = STATUS_ARRAY.find(
    (item) => item.name === status,
  );
  return (
    <Box>
      <Typography fontSize={14} color={decoratedType?.textColor} sx={{
        backgroundColor: decoratedType?.backgroundColor, display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "3px 5px",
        borderRadius: "100px",
        minWidth: "150px",
        maxWidth: "160px",
        textWrap: "nowrap",
      }}>{decoratedType?.label ?? "-"}</Typography>
    </Box>
  )
}

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellPlot = (_: string, cell: any, itemsInPage: any[]): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: 'true' },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);



  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.PLOT, {
      theme: THEME_MODAL.W_555,
      initialValues: cell,
      plotId: cell.id,
    });
    setAnchorEl(null);
  };

  const mutateList =
    swrStore.mutators[SWR_KEYS.getPlotsForRegulatoryInfo()];

  const deleteWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    const promise = cell.status === "closed"
      ? api.regulatoryInfo.deletePlotItemForRegulatoryInfo(cell.id)
      : api.regulatoryInfo.deletePlot(cell.id)

      promise.then(
      () => {
        deleteWithCheckingLast();
        resolve();
      }
    ).catch(reject);
    setAnchorEl(null);
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };

  const canEditPlot = userStore.isPermission(
    PERMISSIONS.regulatory_infoPlot,
    PERMISSION_CRUD.change
  )
  const canDeletePlot = userStore.isPermission(
    PERMISSIONS.regulatory_infoPlot,
    PERMISSION_CRUD.delete
  )

  if (!canEditPlot && !canDeletePlot) {
    return <></>
  }

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {canEditPlot &&
          <StyledMenuItem onClick={handleClickEdit}>
            <EditIcon />
            {EDITING_CELL.editing}
          </StyledMenuItem>
        }
        {canDeletePlot && canEditPlot &&
          <Divider />
        }
        {canDeletePlot &&
          <StyledMenuItem onClick={handleClickDelete}>
            <DeleteIcon color={"error"} />
            <Typography color='error'>{EDITING_CELL.delete}</Typography>
          </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
