import React from "react";
import {
  Typography,
  Grid,
  Box,
  Tooltip,
} from "@mui/material";
import theme from "@/theme";
import ActiveButtons from "./ActiveButtons";
import { resInterface } from "@/api/interfaces";
import { stringWithSuffix } from "@/utils/helpers";

interface IProp {
  item: resInterface.IInvestmentList;
  comPlanFactId: number;
  isHidden?: boolean;
}

const InvestmentItem: React.FC<IProp> = ({
  item,
  comPlanFactId,
  isHidden = false,
}) => {
  const { name, quantity, price, price_total } = item;

  return (
    <Grid
      container
      alignItems="center"
      wrap="nowrap"
      gap="10px"
      padding="12px 15px"
      justifyContent="space-between"
      overflow="auto"
    >
      <Box width="150px">
        <Tooltip
          placement="bottom-start"
          title="Капитальные вложения"
        >
          <Typography variant="body2">{name}</Typography>
        </Tooltip>
      </Box>
      <Box width="150px">
        <Tooltip
          placement="bottom-start"
          title="Количество ед."
        >
          <Typography
            variant="body2"
            color={theme.palette.blackAndWhite.gray}
          >
            {quantity}
          </Typography>
        </Tooltip>
      </Box>
      <Box width="150px">
        <Tooltip
          placement="bottom-start"
          title="Цена, руб."
        >
          <Typography
            variant="body2"
            color={theme.palette.blackAndWhite.gray}
          >
            {stringWithSuffix(price, "руб", "-")}
          </Typography>
        </Tooltip>
      </Box>
      <Box width="150px">
        <Tooltip
          placement="bottom-start"
          title="Стоимость, руб."
        >
          <Typography
            variant="body2"
            color={theme.palette.blue.main}
          >
            {stringWithSuffix(price_total, "руб", "-")}
          </Typography>
        </Tooltip>
      </Box>
      {isHidden ? null : (
        <ActiveButtons
          comPlanFactId={comPlanFactId}
          id={item.id}
          item={item}
        />
      )}
    </Grid>
  );
};

export default InvestmentItem;
