import { resInterface } from "@/api/interfaces";
import yup from "@/utils/yup";

export interface IProps {
    handleClose: () => void;
    modalProps: {
      key: string;
      vegetationCausesId?: number;
      initialValues?: resInterface.IVegetationCauses;
      non_field_errors?: string;
    };
    onSubmit?: () => void;
  }

  type TReason =  'failur' | 'rejection' | 'death'

  export type TForm = {
    name: string,
    code: number,
    type_of_reason: TReason,
    non_field_errors?: string;
  }
  
  export enum VEGETATION_CAUSES_FIELD_NAME {
    CODE = "code", 
    NAME = "name", 
    TYPE_OF_REASON = "type_of_reason", 
  }

  export const mapFormToValue = (values: TForm) => {
    return {
        [VEGETATION_CAUSES_FIELD_NAME.CODE] : values?.code,
        [VEGETATION_CAUSES_FIELD_NAME.NAME]: values?.name,
        [VEGETATION_CAUSES_FIELD_NAME.TYPE_OF_REASON]: values?.type_of_reason
    }
  };

  /*Validation of the form*/

export const validationSchema = yup.object().shape({
    [VEGETATION_CAUSES_FIELD_NAME.TYPE_OF_REASON]: yup.string().required(), 
    [VEGETATION_CAUSES_FIELD_NAME.CODE]: yup.string()
      .required()
      .notOneOf([], 'Это поле не может быть пустым'),
  
      [VEGETATION_CAUSES_FIELD_NAME.NAME]: yup.string()
      .required()
      .trim()
      .notOneOf([''], 'Это поле не может быть пустым'),
  });
  