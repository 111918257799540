import React from "react";
import {
  FastField,
  Field,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext
} from "formik";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { ROUTES, ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";
import { STATEMENT_TYPE } from "@/const";
import useToastContent, { TOAST_CONTENT_TYPE } from "@/hooks/useToastContent";
import { StyledChip } from "@/apps/Modals/ModalContent/CreateVariety/styles";
import AddChip from "@/components/Chips/AddChip";
import { FormikValues } from "formik/dist/types";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { ICultivar, ISubject, IVariety } from "@/api/interfaces/responses";
import { getFullName } from "@/utils/helpers";
import { styled } from "@mui/system";
import {
  defaultValues,
  FIELDS_NAME,
  formToValue,
  IFormik,
  SUBJECT_TYPE,
  validationSchema
} from "./const";
import { DateTimePicker } from "@mui/x-date-pickers";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.blackAndWhite.black,
  fontWeight: 500
}));

interface IAddStatementProps {
  modalKey: string;
}

/**
 * Форма создания заявки
 * @param modalKey - ключ модалки
 */

const AddStatementForm: React.FC<IAddStatementProps> = ({ modalKey }) => {
  const navigate = useNavigate();
  const { modalStore, userStore } = useStores();
  const formik = useFormikContext<IFormik>();
  const handleSelectCultivar = (payload: ICultivar) => {
    modalStore.setModalPropsByKey(modalKey, {
      initialValues: {
        ...formik.values,
        [FIELDS_NAME.CULTIVAR]: payload
      }
    });
  };
  const handleSelectVariety = (payload: IVariety) => {
    modalStore.setModalPropsByKey(modalKey, {
      initialValues: {
        ...formik.values,
        [FIELDS_NAME.VARIETY]: payload
      }
    });
  };
  const handleAddCultivar = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CULTIVAR, {
      onSubmit: handleSelectCultivar
    });
  };
  const handleAddVariety = () => {
    modalStore.open(VARIANT_MODAL.SELECT_VARIETY, {
      onSubmit: handleSelectVariety,
      currentCultivar: formik.values.cultivar
    });
  };
  const handleChangeDate = (value: Date) => {
    formik.setFieldValue(FIELDS_NAME.REGISTRATION_DATE, value);
  };
  const handleSelectApplicant = (payload: ISubject) => {
    const name = payload?.subject_type === SUBJECT_TYPE.INDIVIDUAL ? 
      getFullName(payload):
      payload.company_name;
    modalStore.setModalPropsByKey(modalKey, {
      initialValues: {
        ...formik.values,
        [FIELDS_NAME.APPLICANTS]: { ...payload, name }
      }
    });
  };
  const handleAddApplicant = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CENSUS_TAKER, {
      onSubmit: handleSelectApplicant,
      title: "Заявитель",
      addButtonLabel: "Добавить заявителя"
    });
  };
  const handleDeleteCultivar = () => {
    formik.setFieldValue(FIELDS_NAME.CULTIVAR, "");
    formik.setFieldValue(FIELDS_NAME.VARIETY, "");
  };
  const handleCancel = () => {
    modalStore.close();
    navigate(ROUTES_PATHS.statements);
  };

  const isCulture = userStore.isPermission(
    PERMISSIONS.regulatory_infoСultivar,
    PERMISSION_CRUD.view
  );

  const isSort = userStore.isPermission(
    PERMISSIONS.regulatory_infoVariety,
    PERMISSION_CRUD.view
  );

  const isSubject = userStore.isPermission(
    PERMISSIONS.registrationSubject,
    PERMISSION_CRUD.view
  );

  return (
    <Form noValidate>
      <Box>
        <Box p={3}>
          <Typography variant="h2">Добавить заявку</Typography>
        </Box>
        <Divider />
        <Stack p={3} direction="column" spacing={3}>
          <FastField name={FIELDS_NAME.TYPE}>
            {({ field }) => (
              <FormControl>
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <FormControlLabel
                      value={STATEMENT_TYPE.APPROVAL_STATEMENT}
                      control={<Radio color="secondary" />}
                      label="На допуск"
                    />
                    <FormControlLabel
                      value={STATEMENT_TYPE.PATENT_STATEMENT}
                      control={<Radio color="secondary" />}
                      label="На патент"
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          </FastField>
          <Field name={FIELDS_NAME.CULTIVAR}>
            {({ field, meta }) => (
              <FormControl required>
                <StyledFormLabel>Культура</StyledFormLabel>
                <Tooltip
                  followCursor
                  title={
                    !isCulture
                      ? "У вас недостаточно прав для просмотра списка культур"
                      : ""
                  }
                >
                  <Box>
                    {field.value ? (
                      <StyledChip
                        size="small"
                        variant="outlined"
                        label={field.value.name}
                        onDelete={handleDeleteCultivar}
                      />
                    ) : (
                      <AddChip
                        disabled={!isCulture}
                        onClick={handleAddCultivar}
                      />
                    )}
                  </Box>
                </Tooltip>
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={FIELDS_NAME.VARIETY}>
            {({ field, meta }) => (
              <FormControl required>
                <StyledFormLabel>Сорт</StyledFormLabel>
                <Tooltip
                  followCursor
                  title={
                    !isSort
                      ? "У вас недостаточно прав для просмотра списка сортов"
                      : ""
                  }
                >
                  <Box>
                    {field.value ? (
                      <StyledChip
                        size="small"
                        variant="outlined"
                        label={field.value.name}
                        onDelete={() => formik.setFieldValue(field.name, "")}
                      />
                    ) : (
                      <AddChip
                        disabled={!formik.values.cultivar || !isSort}
                        onClick={handleAddVariety}
                      />
                    )}
                  </Box>
                </Tooltip>
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={FIELDS_NAME.APPLICANTS}>
            {({ field, meta }) => (
              <FormControl required>
                <StyledFormLabel>Заявитель</StyledFormLabel>
                <Tooltip
                  followCursor
                  title={
                    !isSubject
                      ? "У вас недостаточно прав для просмотра списка переписчиков"
                      : ""
                  }
                >
                  <Box>
                    {field.value ? (
                      <StyledChip
                        size="small"
                        variant="outlined"
                        label={field.value.name}
                        onDelete={() => formik.setFieldValue(field.name, "")}
                      />
                    ) : (
                      <AddChip
                        disabled={!isSubject}
                        onClick={handleAddApplicant}
                      />
                    )}
                  </Box>
                </Tooltip>
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <FastField name={FIELDS_NAME.REGISTRATION_DATE}>
            {({ field, meta }) => (
              <DateTimePicker
                name={field.name}
                onChange={handleChangeDate}
                value={field.value}
                label="Дата регистрации"
                format="DD.MM.YYYY HH:mm"
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    required: true,
                    error: meta.touched && !!meta.error,
                    helperText: meta.touched && meta.error,
                    onBlur: field.onBlur
                  }
                }}
              />
            )}
          </FastField>
        </Stack>
        <Divider />
        <Box p={3} display="flex" justifyContent="space-between">
          <Button variant="text" color="red" onClick={handleCancel}>
            Отмена
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Создать
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    initialValues: IFormik;
  };
}

/** Модалка создания заявки */
const CreateStatement: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const {
    key,
    initialValues = {
      ...defaultValues,
      [FIELDS_NAME.REGISTRATION_DATE]: dayjs()
    }
  } = modalProps;
  const { api, toastStore } = useStores();
  const toastContent = useToastContent(TOAST_CONTENT_TYPE.SERVER_ERROR);
  const navigate = useNavigate();

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    api.statement
      .addStatement(formToValue(values))
      .then((res) => {
        handleClose();
        navigate(`${res.id}/${ROUTES.addStatement}`);
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
      component={() => <AddStatementForm modalKey={key} />}
    />
  );
};

export default CreateStatement;
