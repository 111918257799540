import React, { useState } from "react";
import { FastField, Field, FieldArray, Form, useFormikContext } from "formik";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import Autocomplete from "@/components/Autocomplete";
import {
  BranchAutocomplete,
  PlotAutoComplete,
  RegionAutocomplete,
} from "@/components/Autocompletes";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";

import { FIELDS_NAME, IFormik } from "../const";
import { DatePickerField } from "@/components/Fields";
import { ChipWrap } from "@/components/Chips/ChipWrap";
import { IDiseaseList, IEntity, IOrderCultivar, IOrderPlot } from "@/api/interfaces/responses";
import AddChip from "@/components/Chips/AddChip";
import { VARIANT_MODAL } from "@/apps/Modals/const";

interface IProps {
  handleClose: () => void;
  cultivarId: number | string;
  cultivarName: string;
  modalProps: {
    orderCultivar: IOrderCultivar;
    initialValues?: IOrderPlot;
    initialValuesFromDeseasesModal?: IOrderPlot;
    orderPlotId?: number;
    title?: string;
    isEdit?: boolean;
    onSubmit: () => void;
    key?: string;
  };
}

const AddOrderPlotForm: React.FC<IProps> = ({
  handleClose,
  cultivarId,
  cultivarName,
  modalProps,
}) => {
  const { api, modalStore } = useStores();
  const { values, setFieldValue, isSubmitting } =
    useFormikContext<IFormik>();
  const [searchTypes, setSearchTypes] = useState("");
  const [searchGroup, setSearchGroup] = useState("");

  const swrKeyGroups = SWR_KEYS.getCultivarGroupList();
  const swrKeyTypes = SWR_KEYS.getCultivarUsageTypeList();

  const getParametersGroups = (index: number) => ({
    _key: swrKeyGroups,
    page: index + 1,
    usage_type: values[FIELDS_NAME.CULTIVAR_USAGE_TYPE]?.id,
    search: searchGroup,
  });

  const fetcherGroup = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getCultivarGroupList({
      page,
      search,
      usage_type: values.cultivar_usage_type
        ? values.cultivar_usage_type.id
        : undefined,
    });
  };

  const {
    data: dataGroups,
    handleScroll: handleScrollGroups,
  } = useInfiniteScroll(getParametersGroups, fetcherGroup, {
    revalidateFirstPage: false,
  });

  const getParametersTypes = (index: number) => ({
    _key: swrKeyTypes,
    page: index + 1,
    search: searchTypes,
    cultivar: cultivarId,
  });

  const fetcherType = (args) => {
    const { page, search, cultivar } = args;
    return api.regulatoryInfo.getCultivarUsageTypeList({
      page,
      search,
      cultivar,
    });
  };

  const {
    data: dataTypes,
    handleScroll: handleScrollTypes,
  } = useInfiniteScroll(getParametersTypes, fetcherType, {
    revalidateFirstPage: false,
  });

  const handleChangeBranch = (child) => {
    setFieldValue(FIELDS_NAME.BRANCH, child);
    setFieldValue(FIELDS_NAME.PLOT, undefined);
  };

  const handleChangePlot = (child) => {
    setFieldValue(FIELDS_NAME.PLOT, child);
  };

  const handleChangeRegion = (child) => {
    setFieldValue(FIELDS_NAME.REGION, child);
    setFieldValue(FIELDS_NAME.BRANCH, undefined);
    setFieldValue(FIELDS_NAME.PLOT, undefined);
  };

  const handleChangeGroup = (event, child) => {
    void setFieldValue(FIELDS_NAME.CULTIVAR_GROUP, child);
  };

  const handleChangeTypes = (event, child) => {
    setFieldValue(FIELDS_NAME.CULTIVAR_USAGE_TYPE, child);
    setFieldValue(FIELDS_NAME.CULTIVAR_GROUP, undefined);
  };

  const handleRegionClear = () => {
    setFieldValue(FIELDS_NAME.BRANCH, undefined);
    setFieldValue(FIELDS_NAME.PLOT, undefined);
  };

  const handleBranchClear = () => {
    setFieldValue(FIELDS_NAME.PLOT, undefined);
  };

  const getGroupLabel = (payload) => {
    return payload?.variant?.severity ?? "-";
  };

  const isOOS = values[FIELDS_NAME.IS_OOS] === true
  const isPhyto = values[FIELDS_NAME.IS_PHITO] === true
  const valuesForm = values

  const handleAcceptDiseases = (values: IDiseaseList[]) => {

    const newValues = values.map((el) => ({
      id: el.id,
      name: el.name
    }));

    modalStore.setModalPropsByKey(
      modalProps.key,
      {
        ...modalProps,
        initialValuesFromDeseasesModal: {
          ...valuesForm,
          [FIELDS_NAME.DISEASES]: newValues
        },
      }
    )
  };

  const handleOpenModal = () => {
    modalStore.open(VARIANT_MODAL.SELECT_DISEASES, {
      onSubmit: handleAcceptDiseases,
      cultivar: {
        id: cultivarId,
        name: cultivarName,
      },
      initialValue: values.diseases
    });
  };

  const handleChangeIsHP = () => {
    setFieldValue(FIELDS_NAME.IS_HP, true)
    setFieldValue(FIELDS_NAME.IS_OOS, false)
    setFieldValue(FIELDS_NAME.IS_PHITO, false)
  };

  const handleChangeIsOOS = () => {
    setFieldValue(FIELDS_NAME.IS_OOS, true)
    setFieldValue(FIELDS_NAME.IS_HP, false)
    setFieldValue(FIELDS_NAME.IS_PHITO, false)
  };
  
  const handleChangeIsPhito = () => {
    setFieldValue(FIELDS_NAME.IS_PHITO, true)
    setFieldValue(FIELDS_NAME.IS_OOS, false)
    setFieldValue(FIELDS_NAME.IS_HP, false)
  };

  return (
    <Form noValidate>
      <Grid
        container
        direction={"column"}
        mt={1}
        spacing={1}
        p={3}
      >
        <Grid p={1}>
          <RegionAutocomplete
            name={FIELDS_NAME.REGION}
            handleChange={handleChangeRegion}
            onClear={handleRegionClear}
            required={true}
          />
        </Grid>
        <Grid p={1}>
          <BranchAutocomplete
            name={FIELDS_NAME.BRANCH}
            handleChange={handleChangeBranch}
            q={{
              region: values.region?.id ?? undefined,
            }}
            disabled={!values.region}
            onClear={handleBranchClear}
            required={true}
          />
        </Grid>
        <Grid p={1}>
          <PlotAutoComplete
            name={FIELDS_NAME.PLOT}
            handleChange={handleChangePlot}
            q={{
              branch: values.branch?.id ?? undefined,
            }}
            disabled={!values.branch}
            required={true}
          />
        </Grid>
        <Grid p={1}>
          <Field name={FIELDS_NAME.CULTIVAR_USAGE_TYPE}>
            {({ field, meta }) => (
              <FormControl fullWidth required>
                <Autocomplete
                  {...field}
                  onChange={handleChangeTypes}
                  label="Направление использования"
                  data={dataTypes}
                  required
                  handleScroll={handleScrollTypes}
                  search={setSearchTypes}
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>
        <Grid p={1}>
          <Field name={FIELDS_NAME.CULTIVAR_GROUP}>
            {({ field, meta }) => (
              <FormControl fullWidth required>
                <Autocomplete
                  {...field}
                  onChange={handleChangeGroup}
                  getLabel={getGroupLabel}
                  label="Группа"
                  data={dataGroups}
                  required
                  handleScroll={handleScrollGroups}
                  search={setSearchGroup}
                  disabled={
                    !values[FIELDS_NAME.CULTIVAR_USAGE_TYPE]
                  }
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>
        {isOOS ? (
          <Grid p={1} container spacing="20px">
            <Grid item md={6} xs={12}>
              <DatePickerField
                name={FIELDS_NAME.TRIALS_FROM}
                label="Испытания от"
                fullWidth={true}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DatePickerField
                name={FIELDS_NAME.TRIALS_TO}
                label="Испытания до"
                fullWidth={true}
              />
            </Grid>
          </Grid>
        ) : null}
        <FastField>
          {({ field }) => (
            <FormControl>
              <RadioGroup {...field}>
                <Stack
                  p={1}
                  direction="row"
                  alignItems={"center"}
                >
                  <FormLabel>Вид испытаний: </FormLabel>
                  <FormControlLabel
                    value={"hp"}
                    control={<Radio color="secondary" />}
                    label="ХП"
                    onChange={handleChangeIsHP}
                    checked={values[FIELDS_NAME.IS_HP]}

                  />
                  <FormControlLabel
                    value={"oos"}
                    control={<Radio color="secondary"/>
                    }
                    label="ООС"
                    onChange={handleChangeIsOOS}
                    checked={values[FIELDS_NAME.IS_OOS]}
                  />
                  <FormControlLabel
                    value={"phito"}
                    control={<Radio color="secondary"/>}
                    label="ЭФ"
                    onChange={handleChangeIsPhito}
                    checked={values[FIELDS_NAME.IS_PHITO]}
                  />

                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        </FastField>
        {
          isPhyto ?
            <Grid p={1}>
              <FieldArray
                name={FIELDS_NAME.DISEASES}
                render={(helpers) => {
                  const values = helpers.form.values[helpers.name];
                  const meta = helpers.form.getFieldMeta(helpers.name);
                  return (
                    <FormControl required>
                      <FormLabel>Заболевания</FormLabel>
                      <ChipWrap>
                        {Array.isArray(values) &&
                          values.map((value: IEntity, index) => (
                            <Chip
                              key={value.id}
                              size="small"
                              variant="outlined"
                              label={value.name}
                              onDelete={() => helpers.remove(index)}
                            />
                          ))}

                        <Box>
                          <AddChip
                            onClick={handleOpenModal}
                          />
                        </Box>

                      </ChipWrap>
                      <FormHelperText error={meta.touched && !!meta.error}>
                        {meta.touched && meta.error}
                      </FormHelperText>
                    </FormControl>
                  );
                }}
              />
            </Grid>
            : null
        }
      </Grid>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default AddOrderPlotForm;
