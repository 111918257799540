import yup from "@/utils/yup";

import { reqInterface, resInterface } from "@/api/interfaces";
import { transformEmptyToUndefined } from "./util";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    employeeId?: number;
    initialValues?: resInterface.IEmployee;
  };
  onSubmit?: () => void;
}
export interface IEmployeeEmail {
  id: number;
  email: string;
}
export interface IEmployeePhone {
  id: number;
  phone: string;
  name?: string;
}

export interface IEmployeePost {
  id: number;
  name: string;
}
export interface IEmployeePlot {
  id: number;
  name: string;
}
export interface IEmployeeDepartment {
  id: number;
  name: string;
}
export interface IEmployeeBranch {
  id: number;
  name: string;
}

export type TForm = Omit<
  reqInterface.IEmployee,
  | "emails"
  | "phones"
  | "plot"
  | 'post'
  | "branch"
  | "department"
> & {
  emails: IEmployeeEmail[];
  phones: IEmployeePhone[];
  post: IEmployeePost,
  plot: IEmployeePlot[],
  department: IEmployeeDepartment,
  branch: IEmployeeBranch[]
};

export enum EMPLOYEE_FIELD_NAME {
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  MIDDLE_NAME = "middle_name",
  DATIVE_NAME = "dative_name",
  COMMENT = "comment",
  SUBDIVISION = "subdivision",
  RIGHT_SIGN = "right_sign",
  POST = "post",
  DEPARTMENT = "department",
  BRANCH = "branch",
  PLOT = "plot",

  PHONES = "phones",
  EMAILS = "emails",
  SELECT_PLOT = "SELECT_PLOT",
}

export const EMPLOYEE_FORM_STEPS = {
  FIRST: "first",
  SECOND: "second",
} as const;

export const SUBDIVISION_TYPES = [
  { value: "department", label: "Отдел" },
  { value: "branch", label: "Филиал" },
  { value: "gsu", label: "ГСУ" },
];

export const SUBDIVISION_CONST = {
  DEPARTMENT: "department",
  BRANCH: "branch",
  GSU: "gsu",
} as const;

export const mapValueToForm = (
  baseInititialValues,
  employeeData: resInterface.IEmployee,
): TForm => {
  return {
    ...baseInititialValues,
    ...{
      ...employeeData,
      [EMPLOYEE_FIELD_NAME.PHONES]: employeeData.phones?.length
        ? employeeData.phones.map((i, index) => ({
          id: index,
          phone: i.phone,
          name: i.name,
        }))
        : [{ id: 0, phone: "", name: "" }],
      [EMPLOYEE_FIELD_NAME.EMAILS]: employeeData.emails?.length
        ? employeeData.emails.map((i, index) => ({
          id: index,
          email: i.email,
        }))
        : [{ id: 0, email: "" }],

      [EMPLOYEE_FIELD_NAME.POST]: {
        ...employeeData.post,
        label: employeeData.post?.name
      },
      [EMPLOYEE_FIELD_NAME.BRANCH]: employeeData.branch?.length
      ? employeeData.branch.map((i) => ({
        id: i.id,
        name: i.name,
      }))
      : [],
      [EMPLOYEE_FIELD_NAME.DEPARTMENT]: {
        ...employeeData.department,
        label: employeeData.department?.name
      },
      [EMPLOYEE_FIELD_NAME.PLOT]: employeeData.plot?.length
      ? employeeData.plot.map((i) => ({
        id: i.id,
        name: i.name,
      }))
      : [],
    },
  };
};


export const mapFormToValue = (values: TForm) => {
  const emails = values.emails.map((i) => ({
    email: i.email,
  }));
  const phones = values.phones.map((i) => ({
    name: i.name as string,
    phone: i.phone,
  }));
  
  const modificatedPhones = phones.map((phone) => transformEmptyToUndefined(phone)).filter((phone) => phone.phone !== undefined)
  const modificatedEmails = emails.map((email) => transformEmptyToUndefined(email)).filter((email) => email.email !== undefined)

  const isSubBranch = values.subdivision === SUBDIVISION_CONST.BRANCH
  const isSubDept = values.subdivision === SUBDIVISION_CONST.DEPARTMENT
  const isSubGSU = values.subdivision === SUBDIVISION_CONST.GSU

  const gsu = values?.plot?.map((i) => i.id);

  const branch = values?.branch?.map((i) => i.id)

  return {
    ...values,
    [EMPLOYEE_FIELD_NAME.PHONES]: modificatedPhones,
    [EMPLOYEE_FIELD_NAME.EMAILS]: modificatedEmails,
    [EMPLOYEE_FIELD_NAME.POST]: values?.post?.id,
    [EMPLOYEE_FIELD_NAME.BRANCH]: isSubBranch ? branch : [],
    [EMPLOYEE_FIELD_NAME.DEPARTMENT]: isSubDept ? values?.department?.id : '',
    [EMPLOYEE_FIELD_NAME.PLOT]: isSubGSU ? gsu : [],
  };
};


/*Validation of the form*/

export const validationSchema = yup.object().shape({
  [EMPLOYEE_FIELD_NAME.FIRST_NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Это поле не может быть пустым'),

  [EMPLOYEE_FIELD_NAME.LAST_NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Это поле не может быть пустым'),

  [EMPLOYEE_FIELD_NAME.DATIVE_NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Это поле не может быть пустым'),
  [EMPLOYEE_FIELD_NAME.SUBDIVISION]: yup.string()
    .required()
    .notOneOf([''], 'Это поле не может быть пустым'),
});

const phoneSchema = yup.object().shape({
  phone: yup.string().nullable(),
});

const emailSchema = yup.object().shape({
  email: yup.string()
  .matches(
    /.*@.*/g,
    "Неверный формат электронной почты",
  )
  .nullable(),
});

export const lastValidationSchema = yup.object().shape({
  [EMPLOYEE_FIELD_NAME.EMAILS]: yup.array()
    .of(emailSchema)
    .nullable(),
  [EMPLOYEE_FIELD_NAME.PHONES]: yup.array()
    .of(phoneSchema)
    .nullable(),
});