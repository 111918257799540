export const STEP_LABELS = {
  PRODUCTION: "Производство",
  EXPENSES: "Расходы",
  INVESTMENTS: "Капитальные вложения",
  FINANCE: "Финансы",
  EFFICIENCY: "Эффективность",
  FILE_LIST: "Документы"
};

export const STEP_TITLES = {
  PRODUCTION: "Производственный план",
  EXPENSES: "Производственные расходы филиала",
  INVESTMENTS: "План капитальных вложений",
  FINANCE: "",
  EFFICIENCY: "",
};

export const FIELDS_NAME = {
  YEAR_PLAN: "year", // год факта
} as const;

export const TAB_DATA_FACTS = {
  CULTURES: {
    path: "cultures",
    label: "Культуры",
  },
  EXPENSES: {
    path: "expenses",
    label: "Расходы",
  },
} as const;