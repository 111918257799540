import { resInterface } from "@/api/interfaces";

import yup from "@/utils/yup";
import {
  transformEmptyToUndefined
} from "../Employee/util";
import { TYPES_MAP_OBJECT } from "@/apps/Map/const";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    plotId?: number;
    plotStatus?: string;
    initialValues?: resInterface.IPlotList;
    onSubmit?: () => void;
    initialStep: number
  };
}

export const PLOT_FIELD_NAME = {
  NAME: "name",
  FULL_NAME: 'full_name',
  SHORT_NAME: 'short_name',
  VARIETY_ZONE_ID: 'variety_zone_id',
  STATUS: 'status',
  YEAR_CLOSED: 'year_closed',
  COMMENT: 'comment',
  STAFFING: 'staffing',
  VACANCIES: 'vacancies',
  STAFF_DIRECTOR: 'staff_director',
  BRANCH: 'branch',
  DISTRICT: 'district',
  PLOT_DIRECTION: 'plot_direction',
  LEGAL_ADDRESS: 'legal_address',
  ACTUAL_ADDRESS: 'actual_address',
  PHONES: 'phones',
  EMAILS: 'emails',
  MAP_OBJECT: 'map_object',
  LAT: 'lat',
  LNG:'lng',
  REGION:'region'
} as const

export interface IValue {
  id: number;
}

export interface IEmail {
  id?: number;
  email: string;
}
export interface IPhone {
  id?: number;
  phone: string;
  name?: string;
}
export interface TForm {
  [PLOT_FIELD_NAME.NAME]: string;
  [PLOT_FIELD_NAME.FULL_NAME]: string;
  [PLOT_FIELD_NAME.SHORT_NAME]: string;
  [PLOT_FIELD_NAME.VARIETY_ZONE_ID]: number | null;
  [PLOT_FIELD_NAME.STATUS]: string;
  [PLOT_FIELD_NAME.YEAR_CLOSED]: number | null;
  [PLOT_FIELD_NAME.COMMENT]: string;
  [PLOT_FIELD_NAME.STAFFING]: number | null;
  [PLOT_FIELD_NAME.VACANCIES]: number | null;
  [PLOT_FIELD_NAME.STAFF_DIRECTOR]: number | null;
  [PLOT_FIELD_NAME.BRANCH]: IValue | null;
  [PLOT_FIELD_NAME.DISTRICT]: IValue | null;
  [PLOT_FIELD_NAME.PLOT_DIRECTION]: IValue | null;
  [PLOT_FIELD_NAME.LEGAL_ADDRESS]: IValue | null;
  [PLOT_FIELD_NAME.ACTUAL_ADDRESS]: IValue | null;
  [PLOT_FIELD_NAME.PHONES]: IPhone;
  [PLOT_FIELD_NAME.EMAILS]: IEmail;
  [PLOT_FIELD_NAME.LAT]: number;
  [PLOT_FIELD_NAME.LNG]: number;
}


export const mapValueToForm = (value) => {
  return {
    [PLOT_FIELD_NAME.NAME]: value?.name || '',
    [PLOT_FIELD_NAME.FULL_NAME]: value?.full_name || '',
    [PLOT_FIELD_NAME.SHORT_NAME]: value?.short_name || '',
    [PLOT_FIELD_NAME.VARIETY_ZONE_ID]: value?.variety_zone_id || null,
    [PLOT_FIELD_NAME.STATUS]: value?.status || '',
    [PLOT_FIELD_NAME.YEAR_CLOSED]: value?.year_closed || null,
    [PLOT_FIELD_NAME.COMMENT]: value?.comment || '',
    [PLOT_FIELD_NAME.STAFFING]: value?.staffing || null,
    [PLOT_FIELD_NAME.VACANCIES]: value?.vacancies || null,
    [PLOT_FIELD_NAME.STAFF_DIRECTOR]: value?.staff_director ? {
      ...value?.staff_director,
      label: value?.staff_director?.full_name,
    }
      : null,
    [PLOT_FIELD_NAME.BRANCH]: value?.branch ? {
      ...value?.branch,
      label: value.branch?.name,
    }
      : null,
    [PLOT_FIELD_NAME.DISTRICT]: value?.district ? {
      ...value?.district,
      label: value.district?.name,
    }
      : null,
    [PLOT_FIELD_NAME.REGION]: value?.region ? {
      ...value?.region,
      label: value.region?.name,
    }
      : null,
    [PLOT_FIELD_NAME.PLOT_DIRECTION]: value?.plot_direction ? {
      ...value?.plot_direction,
      label: value.plot_direction?.name,
    }
      : null,
    [PLOT_FIELD_NAME.LEGAL_ADDRESS]: value?.legal_address ? {
      ...value.legal_address,
      name: value?.legal_address?.one_line,
    } : null,
    [PLOT_FIELD_NAME.ACTUAL_ADDRESS]: value?.actual_address ? {
      ...value.actual_address,
      name: value?.actual_address?.one_line
    } : null,
    [PLOT_FIELD_NAME.PHONES]: value.phones?.length
      ? value.phones.map((i, index) => ({
        id: index,
        phone: i.phone,
        name: i.name,
      }))
      : [{ id: 0, phone: "", name: "" }],
    [PLOT_FIELD_NAME.EMAILS]: value.emails?.length
      ? value.emails.map((i, index) => ({
        id: index,
        email: i.email,
      }))
      : [{ id: 0, email: "" }],
    [PLOT_FIELD_NAME.LAT]: value.lat ? value.lat : value.map_object?.coordinates
      ? value.map_object.coordinates[1]
      : "",
    [PLOT_FIELD_NAME.LNG]: value.lng ? value.lng : value.map_object?.coordinates
      ? value.map_object.coordinates[0]
      : "",
  }
};


export const mapFormToValue = (values: any) => {
  const emails = values.emails.map((i) => ({
    email: i.email,
  }));
  const phones = values.phones.map((i) => ({
    name: i.name as string,
    phone: i.phone,
  }));

  const modificatedPhones = phones.map((phone) => transformEmptyToUndefined(phone)).filter((phone) => phone.phone !== undefined)
  const modificatedEmails = emails.map((email) => transformEmptyToUndefined(email)).filter((email) => email.email !== undefined)
  return {
    [PLOT_FIELD_NAME.NAME]: values?.name || '',
    [PLOT_FIELD_NAME.FULL_NAME]: values?.full_name || '',
    [PLOT_FIELD_NAME.SHORT_NAME]: values?.short_name || '',
    [PLOT_FIELD_NAME.VARIETY_ZONE_ID]: values?.variety_zone_id || null,
    [PLOT_FIELD_NAME.STATUS]: values?.status || '',
    [PLOT_FIELD_NAME.YEAR_CLOSED]: values?.year_closed || null,
    [PLOT_FIELD_NAME.COMMENT]: values?.comment || '',
    [PLOT_FIELD_NAME.STAFFING]: values?.staffing || null,
    [PLOT_FIELD_NAME.VACANCIES]: values?.vacancies || null,
    [PLOT_FIELD_NAME.STAFF_DIRECTOR]: values?.staff_director?.id || null,
    [PLOT_FIELD_NAME.BRANCH]: values?.branch?.id || null,
    [PLOT_FIELD_NAME.DISTRICT]: values?.district?.id || null,
    [PLOT_FIELD_NAME.PLOT_DIRECTION]: values?.plot_direction?.id || null,
    [PLOT_FIELD_NAME.LEGAL_ADDRESS]: values?.legal_address?.id || null,
    [PLOT_FIELD_NAME.ACTUAL_ADDRESS]: values?.actual_address?.id || null,
    [PLOT_FIELD_NAME.PHONES]: modificatedPhones,
    [PLOT_FIELD_NAME.EMAILS]: modificatedEmails,
    [PLOT_FIELD_NAME.MAP_OBJECT]: values.lng && values.lat ? {type:TYPES_MAP_OBJECT.Point,coordinates:[values.lat, values.lng] } : ''
  };
};

/**
 * Validation schema using Yup for form validation.
 */

const phoneSchema = yup.object().shape({
  phone: yup.string().nullable(),
});


const emailSchema = yup.object().shape({
  email: yup.string()
    .matches(/.*@.*/g,'Неверный формат электронной почты')
    .nullable(),
});

export const firstValidationSchema = yup.object().shape({
  [PLOT_FIELD_NAME.NAME]: yup.string().trim().required(),
  [PLOT_FIELD_NAME.FULL_NAME]: yup.string().trim(),
  [PLOT_FIELD_NAME.SHORT_NAME]: yup.string().trim(),
  [PLOT_FIELD_NAME.BRANCH]: yup.mixed().required(),
  [PLOT_FIELD_NAME.DISTRICT]: yup.mixed().required(),
});
export const secondValidationSchema = yup.object().shape({
  [PLOT_FIELD_NAME.LEGAL_ADDRESS]: yup.mixed().required(),
  [PLOT_FIELD_NAME.ACTUAL_ADDRESS]:yup.mixed().required(),
  [PLOT_FIELD_NAME.PHONES]: yup.array()
  .of(phoneSchema)
  .nullable(),
  [PLOT_FIELD_NAME.EMAILS]: yup.array()
  .of(emailSchema)
  .nullable(),
  [PLOT_FIELD_NAME.LAT]: yup.number(),
  [PLOT_FIELD_NAME.LNG]: yup.number(),
});
export const lastValidationSchema = yup.object().shape({
  [PLOT_FIELD_NAME.COMMENT]: yup.string().trim()
});
