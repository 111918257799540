import { STATEMENT_TYPE } from "@/const";
import dayjs from "@/utils/dayjs";
import { TStatementType } from "@/api/types";
import { IEntity } from "@/api/interfaces/responses";
import yup from "@/utils/yup";

export const FIELDS_NAME = {
  TYPE: "type", // тип заявки
  CULTIVAR: "cultivar", // культура
  VARIETY: "variety", // сорт
  CENSUS_TAKER: "census_taker", // переписчик
  REGISTRATION_DATE: "registration_date", // Дата регистрации
  APPLICANTS: "applicants", // заявитель
} as const;

export const defaultValues = {
  [FIELDS_NAME.TYPE]: STATEMENT_TYPE.APPROVAL_STATEMENT,
  [FIELDS_NAME.CULTIVAR]: "",
  [FIELDS_NAME.VARIETY]: "",
  [FIELDS_NAME.CENSUS_TAKER]: "",
  [FIELDS_NAME.APPLICANTS]: "",
  [FIELDS_NAME.REGISTRATION_DATE]: dayjs()
};

export interface IFormik {
  [FIELDS_NAME.TYPE]: TStatementType;
  [FIELDS_NAME.CULTIVAR]: IEntity;
  [FIELDS_NAME.VARIETY]: IEntity;
  [FIELDS_NAME.CENSUS_TAKER]: IEntity;
  [FIELDS_NAME.APPLICANTS]: IEntity;
  [FIELDS_NAME.REGISTRATION_DATE]: string;
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.TYPE]: yup.string().required(),
  [FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
  [FIELDS_NAME.VARIETY]: yup.mixed().required(),
  [FIELDS_NAME.CENSUS_TAKER]: yup.mixed(),
  [FIELDS_NAME.APPLICANTS]: yup.mixed().required(),
  [FIELDS_NAME.REGISTRATION_DATE]: yup.date().required()
});

export const formToValue = (values: IFormik) => ({
  [FIELDS_NAME.TYPE]: values.type,
  [FIELDS_NAME.REGISTRATION_DATE]: dayjs(values.registration_date).local().toISOString(),
    
  [FIELDS_NAME.VARIETY]: values.variety.id,
  [FIELDS_NAME.CENSUS_TAKER]: values.census_taker.id,
  [FIELDS_NAME.APPLICANTS]: [values.applicants.id],
  [FIELDS_NAME.CULTIVAR]: values.cultivar.id
});

export const SUBJECT_TYPE = {
  INDIVIDUAL: "individual",
  LEGAL_ENTITY: "legal_entity"
}
