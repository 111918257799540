import React, { useState } from "react";
import Autocomplete, {
  SearchFilterProps
} from "@/components/Autocomplete";
import { useInfiniteScroll } from "@/hooks";
import { SWR_KEYS } from "@/const";
import {
  IPaginatedResult
} from "@/api/interfaces/responses";

export interface IProps extends Partial<SearchFilterProps<any>> {
  name: string,
  q?: Record<string, any>,
  handleChange: (payload: any) => any
  getLabel?: (el: any) => string
  fetcher: (...args: any[]) => Promise<IPaginatedResult<any>>
  initialSearch?: string
  disabled?: boolean
  resetValue?: boolean
}

/**
 * Неконтролируемый автокомплит
 * @param name - имя
 * @param q - квери параметры для запроса
 * @param handleChange - калбек при выборе
 * @param initialSearch - начальное значение поиска
 */
const UncontrolledAutoComplete: React.FC<IProps> = ({
  name,
  initialSearch = "",
  q = {},
  handleChange,
  fetcher,
  resetValue = false,
  ...props
}) => {

  if (resetValue) {
    return (
      <Autocomplete
        value={""}
        {...props}
        onChange={() => {}}
        data={[]}
        disabled={true}
      />
    )
  }

  const [value, setValue] = useState(null);
  const [search, setSearch] = useState(initialSearch);

  const getKey = (index: number) => {
    return {
      _key: SWR_KEYS.getEntities(name),
      page: index + 1,
      search: search,
      ...q
    };
  };
  const {
    data,
    handleScroll,
  } = useInfiniteScroll(
    getKey,
    fetcher,
    {
      revalidateFirstPage: false,
    },
  );

  const onChange = (_, payload) => {
    handleChange(payload);
    if (handleChange(payload))
      return setValue(handleChange(payload));
    setValue(payload);
  };

  return (
    <Autocomplete
      value={value}
      {...props}
      onChange={onChange}
      search={setSearch}
      data={data}
      handleScroll={handleScroll}
    />
  );
};

export default UncontrolledAutoComplete;