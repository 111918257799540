import React, { useLayoutEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { asyncComponent } from "react-async-component";
import useSWR from "swr";
import { FormikValues } from "formik/dist/types";
import { Box, CircularProgress } from "@mui/material";

import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import {
  IFormik,
  initialValues,
  serializeLocationValues,
  serializeValues,
  validationSchema
} from "./const";
import { TYPES_MAP_OBJECT } from "@/apps/Map/const";
import { TOAST_TYPES } from "@/apps/Toast";
import {
  errorToString,
  getRandomColor,
  isUndefined
} from "@/utils/helpers";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import {
  KEYS_SIDEBAR_VARIANT_MODULES,
  SWR_KEYS
} from "@/const";
import Loader from "@/components/Loader";
import useRightSidebar from "../../hooks/useRightSidebar";
import {
  RIGHT_SIDEBAR_TYPES
} from "@/apps/RightSidebar/const";

const AddFieldPage = () => {
  const { id, locationId } = useParams();
  const { queryStringSidebarCollector } = useStores();
  const {
    headerStore,
    api,
    toastStore,
    swrStore,
    mapStore
  } = useStores();
  const navigate = useNavigate();
  const { handleOpen } = useRightSidebar();
  const handleBack = () => {
    if (!id && locationId) {
      const { str } = queryStringSidebarCollector.setup({
        tab: undefined,
        keyContent: KEYS_SIDEBAR_VARIANT_MODULES.AGRICULTURAL_WORKS,
        module_id: locationId
      });
      navigate({
        pathname: `${ROUTES_PATHS.agriculturalWork}`,
        search: str
      });
      return
    }
    if (locationId) {
      const { str } = queryStringSidebarCollector.setup({
        tab: undefined,
        keyContent: KEYS_SIDEBAR_VARIANT_MODULES.AGRICULTURAL_WORKS,
        module_id: locationId,
        keyDetail: KEYS_SIDEBAR_VARIANT_MODULES.AGRICULTURAL_WORKS_FIELDS,
        detail_id: id
      });
      navigate({
        pathname: `${ROUTES_PATHS.agriculturalWork}`,
        search: str
      });
      handleOpen({
        type: RIGHT_SIDEBAR_TYPES.AGRICULTURAL_WORKS_FIELDS,
        modalProps: {
          id: locationId,
          detail_id: id,
        }
      });
    } else navigate(`${ROUTES_PATHS.agriculturalWork}`);
  };
  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };
  const { data, isLoading, mutate, error } = useSWR(
    id ? SWR_KEYS.getAgriculturalLabourField(id) : null,
    () => api.agriculturalLabour.getFieldById(id as string),
    {
      onError: showToast
    }
  );
  const {
    data: locationData,
    isLoading: isLocationLoading,
    error: locationError
  } = useSWR(
    locationId ? SWR_KEYS.getAgriculturalLabourLocation(locationId) : null,
    () => api.agriculturalLabour.getLocationById(locationId as string),
    {
      onError: showToast
    }
  );

  const mutateList =
    swrStore.mutators[SWR_KEYS.getAgriculturalLabourFieldList()];
  useLayoutEffect(() => {
    headerStore.setProps({
      handleBack
    });
    headerStore.setHeader(VARIANT_HEADER.ADD_FIELD);
  }, []);
  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    if (values.coordinates.length) {
      values.coordinates.push(values.coordinates[0] as unknown as any);
    }

    const data = {
      name: values.name,
      location: values.location?.id as number,
      colour: values.colour,
      planting_year: values.planting_year as number,
      destination: values.destination?.id as number,
      area: values.area,
      map_object: values.coordinates.length ? JSON.stringify({
        type: TYPES_MAP_OBJECT.Polygon,
        coordinates: [values.coordinates.map((el) => [el[1], el[0]])]
      }) : null
    };

    const promise = id
      ? api.agriculturalLabour.updateField(id, data)
      : api.agriculturalLabour.createField(data);
    promise
      .then(() => {
        handleBack();
        mutateList && mutateList();
        id && mutate();
      })
      .catch((error) => {
        showToast(error);
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mapStore.setInitialValuesForms({});
      });
  };

  if (isLoading || isLocationLoading) {
    return (
      <Box height={500}>
        <Loader/>
      </Box>
    );
  }

  if (error || locationError) {
    return null;
  }

  const locationValues = !isUndefined(locationData)
    ? serializeLocationValues(locationData)
    : {};

  const values = id
    ? serializeValues(data)
    : {
      ...initialValues,
      ...locationValues,
      colour: getRandomColor()
    };

  const FieldData = asyncComponent({
    resolve: () => import("@/pages/AddField/components/FieldData"),
    LoadingComponent: () => <CircularProgress/>
  });

  const hasLocationCoordinates = data ? !!data.location.map_object : !!locationData?.map_object;
  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      component={() => (
        <FieldData
          hasLocationCoordinates={hasLocationCoordinates}
          handleBack={handleBack} locationId={locationId}/>
      )}
    />
  );
};

export default AddFieldPage;
