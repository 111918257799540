import React from "react";
import DOMPurify from "dompurify";
import { Alert, AlertProps, AlertTitle } from "@mui/material";

interface IAlert extends AlertProps {
  message: string;
  title?: string;
  allowHtml?: boolean; // Флаг для включения HTML
}

const AlertToast: React.FC<IAlert> = ({
  message,
  title,
  allowHtml = false,
  ...props
}) => {
  const sanitizedMessage = allowHtml ? DOMPurify.sanitize(message) : message;

  return (
    <Alert
      severity="warning"
      variant="filled"
      sx={{ width: "100%", overflow: "auto", maxHeight: "300px" }}
      {...props}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {allowHtml ? (
        <div dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
      ) : (
        <span>{message}</span>
      )}
    </Alert>
  );
};

export default AlertToast;
