import React from "react";
import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import ModalStepper from "@/components/ModalStepper";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import PlotFormFirstStep from "./components/first";
import PlotFormSecondStep from "./components/second";
import {
  firstValidationSchema,
  IProps,
  lastValidationSchema,
  mapFormToValue,
  mapValueToForm,
  secondValidationSchema
} from "./const";
import PlotFormThirdStep from "./components/third";

/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const Plot: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { api, toastStore, swrStore } = useStores();
  const { plotId, plotStatus } = modalProps;
  const initialValues = modalProps.initialValues;
  const baseInititialValues = {
    name: "",
    full_name: "",
    short_name: "",
    status: "active",
    district: null,
    branch: null,
    plot_direction: null,
    emails: [{ id: 0, email: "" }],
    phones: [{ id: 0, phone: "", name: "" }],
    variety_zone_id: null,
    year_closed: null,
    comment: "",
    staffing: null,
    vacancies: null,
    staff_director: null,
    legal_address: null,
    actual_address: null,
    lat: "",
    lng: ""
  };

  const mutate = plotId
    ? swrStore.mutators[SWR_KEYS.getPlotItemForRegulatoryInfo(plotId)]
    : null;

  const mutateDetail = plotId
    ? swrStore.mutators[SWR_KEYS.getPlotById(plotId)]
    : null;

  const mutateList =
    swrStore.mutators[SWR_KEYS.getPlotsForRegulatoryInfo()];

  const handleSubmit = (
    values: any,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    if (plotId) {
      const patchFetcher = plotStatus === "closed"
        ? api.regulatoryInfo.patchPlotItemForRegulatoryInfo(
          plotId as number,
          mapFormToValue(values)
        )
        : api.regulatoryInfo.patchPlot(
          plotId as number,
          mapFormToValue(values),
        )

      return patchFetcher
        .then(() => {
          mutate && mutate();
          mutateList && mutateList();
          mutateDetail && mutateDetail();
          handleClose();
        })
        .catch((error: AxiosError) => {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    }

    const promise = api.regulatoryInfo.postPlot(mapFormToValue(values));

    return promise
      .then(() => {
        mutate && mutate();
        mutateList && mutateList();
        mutateDetail && mutateDetail();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errorMessage = errorToString(error);
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorMessage,
            severity: "error",
          },
        });
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const subtitle = plotId
    ? "Редактировать запись"
    : "Добавить запись";

  const values = initialValues
    ? {
      ...baseInititialValues,
      ...mapValueToForm(initialValues),
    }
    : baseInititialValues;

  return (
    <ModalStepper
      title="ГСУ"
      subtitle={subtitle}
      initialValues={values}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      enableReinitialize={true}
      initialStep={modalProps.initialStep || 0}
    >
      <ModalStepper.Step
        validationSchema={firstValidationSchema}
      >
        <PlotFormFirstStep
          modalProps={modalProps}
          handleClose={handleClose}
        />
      </ModalStepper.Step>

      <ModalStepper.Step
        validationSchema={secondValidationSchema}
      >
        <PlotFormSecondStep
          modalProps={modalProps}
          handleClose={handleClose}
        />
      </ModalStepper.Step>

      <ModalStepper.Step
        validationSchema={lastValidationSchema}
      >
        <PlotFormThirdStep
          modalProps={modalProps}
          handleClose={handleClose}
        />
      </ModalStepper.Step>
    </ModalStepper>
  );
};

export default Plot;
