import React from "react";
import { observer } from "mobx-react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { Box, Divider, Typography } from "@mui/material";
import theme from "@/theme";
import { useCurrentUser, useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import {
  errorToString,
} from "@/utils/helpers";

import {
  IOrderCultivar,
  IOrderPlot,
} from "@/api/interfaces/responses";
import AddOrderPlotForm
  from "./components/AddOrderPlotForm";
import {
  FIELDS_NAME,
  IFormik,
  validationSchema,
} from "./const";
import { convertInitialValues, convertInitialValuesFromDeseasesModal, formToValueSerializer } from "./utils";


/**
 * Компонент модалки добавления инвестиции для внебюджета
 * @param props
 * @param props.id - id плана внебюджета
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    orderCultivar: IOrderCultivar;
    initialValues?: IOrderPlot;
    initialValuesFromDeseasesModal?: any;
    orderPlotId?: number;
    title?: string;
    isEdit?: boolean;
    onSubmit: () => void;
    key?: string;
  };
}

const AddOrderPlot: React.FC<IProps> = (props) => {
  const {
    api,
    toastStore,
    modalStore,
  } = useStores();
  const {
    modalProps: {
      orderCultivar,
      initialValues,
      initialValuesFromDeseasesModal,
      onSubmit,
      title,
    },
  } = props;
  const { id } = useCurrentUser();


  const initValues = Object.assign(
    {
      [FIELDS_NAME.REGION]: null,
      [FIELDS_NAME.BRANCH]: null,
      [FIELDS_NAME.PLOT]: null,
      [FIELDS_NAME.ORDER_CULTIVAR]:
        orderCultivar?.id ?? null,
      [FIELDS_NAME.CULTIVAR_USAGE_TYPE]: null,
      [FIELDS_NAME.CULTIVAR_GROUP]: null,
      [FIELDS_NAME.IS_HP]: false,
      [FIELDS_NAME.IS_OOS]: false,
      [FIELDS_NAME.IS_PHITO]: false,
      [FIELDS_NAME.TRIALS_TO]: null,
      [FIELDS_NAME.TRIALS_FROM]: null,
      [FIELDS_NAME.DISEASES]: [],
    },
    convertInitialValues(initialValues),
    convertInitialValuesFromDeseasesModal(
      initialValuesFromDeseasesModal
    )
  );

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    const payload = {
      ...formToValueSerializer(values),
      editor: id ?? 0,
    };

    initialValues?.id
      ? api.regulatoryInfo
        .updateOrderPlot(initialValues?.id, payload)
        .then(() => {
          onSubmit();
          modalStore.pop();
        })
        .catch((error) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        })
        .finally(() => {
          helpers.setSubmitting(false);
        })
      : api.regulatoryInfo
        .postOrderPlot(payload as any)
        .then(() => {
          onSubmit();
          modalStore.pop();
        })
        .catch((error) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
  };

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography
          variant="subtitle2"
          fontSize={12}
          color={theme.palette.blackAndWhite.gray}
        >
          {orderCultivar?.cultivar?.name}
        </Typography>
        <Typography variant="h2" fontSize={24}>
          {title ?? "Добавить ГСУ"}
        </Typography>
      </Box>
      <Divider />
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => (
          <AddOrderPlotForm
            {...props}
            cultivarId={orderCultivar?.cultivar?.id}
            cultivarName={orderCultivar?.cultivar?.name}
            modalProps={props.modalProps}
          />
        )}
      />
    </Box>
  );
};

export default observer(AddOrderPlot);
