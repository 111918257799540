import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Dayjs } from "dayjs";
import { Form, useFormikContext, FastField } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid
} from "@mui/material";
import {
  CultivarAutocomplete,
  RegionAutocomplete,
  BranchAutocomplete,
  PlotAutoComplete,
  LocationAutoComplete,
  FieldAutoComplete,
  UnitAutocomplete,
  TechMapPlanAutocomplete,
  PlanAutocomplete
} from "@/components/Autocompletes";
import {
  FIELDS_NAME,
  IFormik,
  trialTypeOptions
} from "../../const";
import CheckIcon from "@mui/icons-material/Check";
import {
  IRegionItem,
  IBranchItem,
  IPlot,
  IAgriculturalLabourLocation,
  IAgriculturalLabourField,
  ICultivar
} from "@/api/interfaces/responses";
import FastRegexTextField
  from "@/components/FastRegexTextField";
import { FormikSelect } from "@/components/Selects";
import theme from "@/theme";

const CultivarFieldForm = () => {
  const formik = useFormikContext<IFormik>();

  const handleRegionClear = () => {
    void formik.setFieldValue(FIELDS_NAME.REGION, "");
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
    void formik.setFieldValue(FIELDS_NAME.FIELD, "");
  };

  const handleChangeRegion = (payload: IRegionItem) => {
    void formik.setFieldValue(FIELDS_NAME.REGION, payload);
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
    void formik.setFieldValue(FIELDS_NAME.FIELD, "");
  };

  const handleBranchClear = () => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
    void formik.setFieldValue(FIELDS_NAME.FIELD, "");
  };

  const handleChangeBranch = (payload: IBranchItem) => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, payload);
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
    void formik.setFieldValue(FIELDS_NAME.FIELD, "");
  };

  const handlePlotClear = () => {
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
    void formik.setFieldValue(FIELDS_NAME.FIELD, "");
  };

  const handleChangePlot = (payload: IPlot) => {
    void formik.setFieldValue(FIELDS_NAME.PLOT, payload);
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
    void formik.setFieldValue(FIELDS_NAME.FIELD, "");
  };

  const handleLocationClear = () => {
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
    void formik.setFieldValue(FIELDS_NAME.FIELD, "");
  };

  const handleChangeLocation = (payload: IAgriculturalLabourLocation) => {
    void formik.setFieldValue(FIELDS_NAME.LOCATION, payload);
    void formik.setFieldValue(FIELDS_NAME.FIELD, "");
  };

  const handleChangeCultivar = (payload: ICultivar) => {
    void formik.setFieldValue(FIELDS_NAME.CULTIVAR, payload);
    void formik.setFieldValue(FIELDS_NAME.TECH_MAP_PLAN, "");
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleChangeTypeTrial = (payload: string) => {
    void  formik.setFieldValue(FIELDS_NAME.TYPE_TRIAL, payload)
    void  formik.setFieldValue(FIELDS_NAME.PLAN, "")
  }

  const handleCultivarClear = () => {
    void formik.setFieldValue(FIELDS_NAME.CULTIVAR, "");
    void formik.setFieldValue(FIELDS_NAME.TECH_MAP_PLAN, "");
  };

  const handleFieldClear = () => {
    void formik.setFieldValue(FIELDS_NAME.FIELD, "");
  };

  const handleChangeField = (payload: IAgriculturalLabourField) => {
    void formik.setFieldValue(FIELDS_NAME.FIELD, payload);
  };

  const handleChangeYear = (value: Dayjs) => {
    void formik.setFieldValue(FIELDS_NAME.YEAR, value);
  };

  const sowingArea = useMemo(() => {
    if (formik.values.sowing_width && formik.values.sowing_length) {
      return Number(formik.values.sowing_width) * Number(formik.values.sowing_length);
    }
    return 0;
  }, [formik.values.sowing_width, formik.values.sowing_length]);

  const accountingArea = useMemo(() => {
    if (formik.values.accounting_width && formik.values.accounting_length) {
      return Number(formik.values.accounting_width) * Number(formik.values.accounting_length);
    }
    return 0;
  }, [formik.values.accounting_length, formik.values.accounting_width]);

  return (
    <Form noValidate>
      <Box p={3} sx={{ maxWidth: 750 }}>
        <Typography variant="h3" marginBottom="20px">
          Данные плана
        </Typography>
        <Stack direction="column" gap="20px" mb="30px">
          <Grid container spacing="20px">
            <Grid item sm={6} xs={12}>
              <CultivarAutocomplete
                name={FIELDS_NAME.CULTIVAR}
                handleChange={handleChangeCultivar}
                onClear={handleCultivarClear}
                required
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FastField name={FIELDS_NAME.YEAR}>
                {({ field, meta }) => (
                  <DatePicker
                    name={field.name}
                    value={field.value}
                    onChange={handleChangeYear}
                    label="Год посева"
                    openTo="year"
                    views={["year"]}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        error: meta.touched && !!meta.error,
                        helperText: meta.error,
                        onBlur: field.onBlur
                      }
                    }}
                  />
                )}
              </FastField>
            </Grid>
          </Grid>
          <Grid container spacing="20px">
            <Grid item sm={6} xs={12}>
              <RegionAutocomplete name={FIELDS_NAME.REGION}
                                  handleChange={handleChangeRegion}
                                  onClear={handleRegionClear}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <BranchAutocomplete name={FIELDS_NAME.BRANCH}
                                  q={{ region: formik.values.region?.id }}
                                  handleChange={handleChangeBranch}
                                  onClear={handleBranchClear}
                                  disabled={!formik.values.region}
              />
            </Grid>
          </Grid>
          <Grid container spacing="20px">
            <Grid item sm={6} xs={12}>
              <PlotAutoComplete name={FIELDS_NAME.PLOT}
                                handleChange={handleChangePlot}
                                q={{ branch: formik.values.branch?.id }}
                                onClear={handlePlotClear}
                                disabled={!formik.values.branch}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <LocationAutoComplete
                name={FIELDS_NAME.LOCATION}
                handleChange={handleChangeLocation}
                q={{ plot: formik.values.plot?.id }}
                onClear={handleLocationClear}
                disabled={!formik.values.plot}
              />
            </Grid>
          </Grid>
          <Grid container spacing="20px">
            <Grid item sm={6} xs={12}>
              <FieldAutoComplete name={FIELDS_NAME.FIELD}
                                 handleChange={handleChangeField}
                                 q={{
                                   location: formik.values.location?.id,
                                   planting_year: formik.values.year?.$y
                                 }}
                                 required
                                 onClear={handleFieldClear}
                                 disabled={!formik.values.location || !formik.values.year}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FastRegexTextField
                name={FIELDS_NAME.AREA}
                label="Площадь, га"
                type="decimal"
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing="20px">
            <Grid item sm={6} xs={12}>
              <FormikSelect
                name={FIELDS_NAME.TYPE_TRIAL}
                handleChange={handleChangeTypeTrial}
                options={trialTypeOptions}
                label="Тип испытания"
                fullWidth={true}
                required
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <CultivarAutocomplete
                name={FIELDS_NAME.PREDECESSOR}
                label="Предшественник"
              />
            </Grid>
          </Grid>
          <Grid container spacing="20px">
            <Grid item sm={6} xs={12}>
              <FastRegexTextField
                name={FIELDS_NAME.SEEDING_RATE}
                label="Норма высева"
                regexType="decimal"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <UnitAutocomplete
                name={FIELDS_NAME.UNIT}
              />
            </Grid>
          </Grid>
          <Grid container spacing="20px">
            <Grid item sm={6} xs={12}>
              <FastRegexTextField
                name={FIELDS_NAME.REPETITIONS_NUMBER}
                label="Количество повторений"
                regexType="number"
                required
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FastRegexTextField
                name={FIELDS_NAME.LAYERS_NUMBER}
                label="Количество ярусов"
                regexType="number"
              />
            </Grid>
          </Grid>
          <Grid container spacing="20px">
            <Grid item sm={6} xs={12}>
              <FastRegexTextField
                name={FIELDS_NAME.MAIN_PRODUCTION}
                label="Производство основной продукции, ц."
                regexType="decimal"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FastRegexTextField
                name={FIELDS_NAME.BY_PRODUCTS_PRODUCTION}
                label="Производство побочной продукции, ц."
                regexType="decimal"
              />
            </Grid>
          </Grid>
          <FastRegexTextField
            name={FIELDS_NAME.GARDENBED_PER_REPETITION}
            label="Количество делянок в 1 повторении"
            regexType="number"
            required

          />
          <PlanAutocomplete name={FIELDS_NAME.PLAN} required
                            q={{
                              status: "approved",
                              cultivar: formik.values.cultivar?.id,
                              trial_type: formik.values.type_trial || undefined,
                              expert_assessment: false,
                              plot: formik.values.plot?.id,
                            }}/>
        </Stack>
        <Stack direction="column" gap="20px" mb="20px">
          <Typography variant="body2">
            Размер делянок
          </Typography>
          <Grid container spacing="20px">
            <Grid item sm={4} xs={12}>
              <FastRegexTextField
                regexType="decimal"
                name={FIELDS_NAME.SOWING_WIDTH}
                label="Посевная ширина"
                required
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FastRegexTextField
                regexType="decimal"
                name={FIELDS_NAME.SOWING_LENGTH}
                label="Посевная длина"
                required
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body2"
                          color={theme.palette.blackAndWhite.gray}>
                Посевная площадь:
              </Typography>
              <Typography variant="p16">
                {sowingArea} м2
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing="20px">
            <Grid item sm={4} xs={12}>
              <FastRegexTextField
                regexType="decimal"
                name={FIELDS_NAME.ACCOUNTING_WIDTH}
                label="Учетная ширина"
                required
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FastRegexTextField
                regexType="decimal"
                name={FIELDS_NAME.ACCOUNTING_LENGTH}
                label="Учетная длина"
                required
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body2"
                          color={theme.palette.blackAndWhite.gray}>
                Учетная площадь:
              </Typography>
              <Typography variant="p16">
                {accountingArea} м2
              </Typography>
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="column" gap="20px" mb="20px">
          <Typography variant="h3">
            Тех. карта
          </Typography>
          <TechMapPlanAutocomplete
            name={FIELDS_NAME.TECH_MAP_PLAN}
            q={{ 
              cultivar: formik.values.cultivar?.id,
              plot: formik.values.plot?.id,
            }}
            disabled={!formik.values.cultivar?.id}
            required={true}/>
        </Stack>
        <Box display="flex" justifyContent="space-between"
             mt={5}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<CheckIcon/>}
            disabled={formik.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default observer(CultivarFieldForm);
