import React, { useEffect, useState } from "react";
import { AddEntity } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { FILTERS_TYPE, PAGE_SIZE, TRIAL_TYPE } from "@/const";
import {
  IVariety,
  IRegionItem,
} from "@/api/interfaces/responses";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import {
  PERMISSION_CRUD,
  PERMISSIONS,
} from "@/premissions";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    currentCultivar: { id: number; name: string };
    onSubmit: (payload: IVariety) => void;
    initialValue?: IVariety;
    key: string;
    subTitle?: string;
    groupId?: number;
    trialType?: string;
    is_standard?: boolean;
  };
}

const Q = {
  IN_REGISTER: "in_register",
  REGIONS: "regions",
  CANDIDATE_IN_REGISTER: "candidate_in_register",
  IS_CONDITIONAL_SORT: "is_conditional_sort",
  PAGE: "page",
  SEARCH: "search",
  STANDART_GROUP_PLAN: "standard_group_plan",
} as const;

interface IArgs {
  [Q.PAGE]: number;
  [Q.SEARCH]: string;
  [Q.IN_REGISTER]: boolean | undefined;
  [Q.REGIONS]: number | null | undefined;
  [Q.IS_CONDITIONAL_SORT]: boolean | undefined;
  [Q.STANDART_GROUP_PLAN]: number | undefined;
}

/** Модалка с выбором Стандарта */

const SelectStandard: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps,
}) => {
  const { modalStore, api, userStore } = useStores();
  const isTrialTypeHP = modalProps.trialType === TRIAL_TYPE.HP
  const [q, setQ] = useState<Partial<IArgs>>({
    in_register: true
  });
  const handleAccept = (value: IVariety) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handlePop();
  };
  const fetcher = (q: IArgs) => {
    return api.regulatoryInfo.getVarietyList({
      page: q.page,
      page_size: PAGE_SIZE,
      search: q.search,
      in_register: q.in_register || undefined,
      regions: q.regions || undefined,
      cultivar: q.is_conditional_sort
        ? undefined
        : modalProps.currentCultivar.id,
      first_ids: modalProps.initialValue?.id,
      standard_group_plan: isTrialTypeHP ? modalProps.groupId : undefined,
      is_standard: modalProps.is_standard ? true : undefined,
    });
  };
  const handleSubmit = (payload: IVariety) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      ...modalProps,
      initialValue: payload,
    });
  };
  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.CREATE_VARIETY, {
      onSubmit: handleSubmit,
      initialValue: {
        cultivar: modalProps.currentCultivar,
      },
      isStatement: true,
      cultivarEditable: false,
    });
  };
  const getLabel = (value: IVariety): string => value.name;
  const getMeta = (value: IVariety) => String(value.code);

  const isSort = userStore.isPermission(
    PERMISSIONS.regulatory_infoVariety,
    PERMISSION_CRUD.add,
  );

  useEffect(() => {
    const updateQ = () => {
      setQ((prev) => ({
        ...prev,
        [Q.STANDART_GROUP_PLAN]: isTrialTypeHP ? modalProps.groupId : undefined,
      }));
    };
  
    updateQ();

  }, [modalProps.groupId]);

  const filters = [
    {
      type: FILTERS_TYPE.AUTOCOMPLETE,
      name: Q.REGIONS,
      label: "Регион",
      fetcher: api.regulatoryInfo.getRegionList,
      handleChange: (region: IRegionItem | null) => {
        setQ((prev) => ({
          ...prev,
          [Q.REGIONS]: region
            ? region.id
            : undefined,
        }));
      },
    },
    {
      type: FILTERS_TYPE.CHECKBOX,
      name: Q.IN_REGISTER,
      label: "В реестре",
      checked: q.in_register,
      handleChange: (
        e: React.ChangeEvent<HTMLInputElement>,
      ) => {
        setQ((prev) => ({
          ...prev,
          [Q.IN_REGISTER]: e.target.checked,
        }));
      },
    },
    {
      type: FILTERS_TYPE.CHECKBOX,
      name: Q.IS_CONDITIONAL_SORT,
      label: "Условный стандарт",
      handleChange: (
        e: React.ChangeEvent<HTMLInputElement>,
      ) => {
        setQ((prev) => ({
          ...prev,
          [Q.IS_CONDITIONAL_SORT]: e.target.checked,
        }));
      },
    },
  ];

  const props = {
    initialValue: modalProps.initialValue,
    key: [
      "GET_VARIETY",
      modalProps.currentCultivar.id,
      modalProps.initialValue?.id,
    ],
    addButtonLabel: "Добавить сорт",
    isDisabledBtnAdd: isSort,
    listTitle: "Сорта",
    filters,
    q,
    title: "Выберите стандарт",
    subTitle: modalProps.subTitle,
    handleAccept,
    handleAdd,
    getLabel: getLabel,
    getMeta: getMeta,
    fetcher,
    acceptButtonLabel: "Добавить",
  };

  return (
    <AddEntity<IVariety>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectStandard;
