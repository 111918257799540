import yup from "@/utils/yup";
import { IAgriculturalLabourLocation, IPlot } from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  PLOT: "plot",
  LOCATION: "location",
  YEAR: "year",
} as const;

export interface IFormik {
  [FIELDS_NAME.PLOT]: IPlot | null,
  [FIELDS_NAME.LOCATION]: IAgriculturalLabourLocation | null,
  [FIELDS_NAME.YEAR]: number | null,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.PLOT]: yup.mixed().required(),
  [FIELDS_NAME.LOCATION]: yup.mixed().required(),
  [FIELDS_NAME.YEAR]: yup.date().required(),
});

export const initialValues = {
  [FIELDS_NAME.PLOT]: null,
  [FIELDS_NAME.LOCATION]: null,
  [FIELDS_NAME.YEAR]: null,
};