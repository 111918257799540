import yup from "@/utils/yup";
import { Dayjs } from "dayjs";

export const FIELDS_NAME = {
  DATE: "vegetation_date",
} as const;

export interface IFormik {
  [FIELDS_NAME.DATE]: Dayjs | string | null,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.DATE]: yup.date().required()
});

export const initialValues = {
  [FIELDS_NAME.DATE]: null,
};