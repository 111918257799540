import { TStatementStatus } from "@/api/types";
import { IValue } from "@/interfaces";
import theme from "@/theme";

export const STATEMENT_KEYS = {
  status: "status",
  id: "id",
  type: "type",
  executor: "executor",
  created_at: "created_at",
  editing: "editing",
  registration_date: "registration_date",
  applicant: "applicant"
} as const;

export const STATEMENT_HEADER_LABELS = {
  status: "Статус",
  number: "Номер",
  type: "Тип заявки",
  executor: "Исполнитель",
  date: "Дата регистрации",
  editing: "Меню",
  applicant: "Заявитель",
};

export const STATEMENT_STATUS: { [key in TStatementStatus]: string } = {
  registration: "Регистрация",
  review: "Экспертиза",
  refinement: "Доработка",
  accepted: "Принята",
  rejected: "Отклонена"
} as const;

export const STATEMENT_STATUS_NAMES = {
  registration: "registration",
  review: "review",
  refinement: "refinement",
  accepted: "accepted",
  rejected: "rejected"
} as const;

export interface IColorsStatus {
  background: string;
  text: string;
}

export const STATEMENT_STATUS_COLORS: {
  [key in TStatementStatus]: IColorsStatus;
} = {
  registration: {
    background: theme.palette.blackAndWhite.grayLight,
    text: theme.palette.blackAndWhite.black
  },
  review: {
    background: theme.palette.another.lightViolet,
    text: theme.palette.another.violet
  },
  refinement: {
    background: theme.palette.yellow.superLightYellow,
    text: theme.palette.yellow.darkYellow
  },
  accepted: {
    background: theme.palette.green.superLightGreen,
    text: theme.palette.green.main
  },
  rejected: {
    background: theme.palette.red.superLightRed,
    text: theme.palette.red.main
  }
} as const;

export const STATEMENT_TYPES = {
  approval_statement: "Заявка на допуск",
  patent_statement: "Заявка на патент"
} as const;

export const STATEMENT_STATUS_INPUT: IValue[] = [
  {
    key: "registration",
    label: "Регистрация"
  },
  {
    key: "review",
    label: "Экспертиза"
  },
  {
    key: "refinement",
    label: "Доработка"
  },
  {
    key: "accepted",
    label: "Заявка принята"
  },
  {
    key: "rejected",
    label: "Заявка отклонена"
  }
];

export const USERS_KEY = "getUsersInfinite";

export const EMPTY_LIST_STATEMENT_TEXT = "Еще не создано ни одной заявки";
export const EMPTY_LIST_STATEMENT_TEXT_WITH_FILTERS =
  "По указанным параметрам заявки не найдены";
export const ADD_STATEMENT_TEXT = "Добавить заявку";

export const SIDEBAR_TABS_NAMES = {
  registration: "registration",
  trial: "trial",
  duties: "duties",
  remarks: "remarks",
  distribution: "distribution"
};

