import React from "react";
import { AddEntity } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE } from "@/const";
import { resInterface } from "@/api/interfaces";
import {
  PERMISSION_CRUD,
  PERMISSIONS
} from "@/premissions";
import { VARIANT_MODAL } from "@/apps/Modals/const";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    key: string,
    initialValue?: resInterface.ISubject,
    onSubmit?: (value: resInterface.ISubject) => void
    title?: string
    addButtonLabel?: string
  };
}

/** Модалка выбора переписчика */

const SelectCensusTaker: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps
}) => {
  const { api, userStore, modalStore } = useStores();
  const handleAccept = (value: resInterface.ISubject) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handlePop();
  };

  const handleAcceptCensusTaker = (payload) => {
    modalStore.setModalPropsByKey(modalProps.key, {...modalProps, initialValue: payload})
  }
  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.SUBJECT, { handleAccept: handleAcceptCensusTaker })
  }


  const fetcher = (args) => {
    const { page, search } = args;
    return api.statement.getSubjects({
      page,
      page_size: PAGE_SIZE,
      search,
      first_ids: modalProps.initialValue?.id
    });
  };

  const getLabel = (payload: resInterface.ISubject) => payload.name;


  const isSubject = userStore.isPermission(
    PERMISSIONS.registrationSubject,
    PERMISSION_CRUD.add
  );

  const props = {
    ...modalProps,
    key: ["GET_CENSUS_TAKER", modalProps.initialValue?.id],
    addButtonLabel: modalProps?.addButtonLabel ? modalProps?.addButtonLabel : "Добавить переписчика",
    isDisabledBtnAdd: isSubject,
    handleAdd,
    tooltipText: "У вас недостаточно прав для добавления переписчика",
    title: modalProps?.title ? modalProps?.title : "Переписчик",
    handleAccept,
    getLabel: getLabel,
    fetcher,
  };

  return (
    <AddEntity<resInterface.ISubject>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectCensusTaker;