import React from "react";
import {
  useNavigate,
  useSearchParams
} from "react-router-dom";
import dayjs from "dayjs";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  CopyIcon,
  DeleteIcon,
  EditIcon
} from "@/components/icons";
import {
  STATEMET_TYPES_SUBMIT
} from "@/apps/Modals/ModalContent/AddStatement/AddStatement";
import { ROUTES } from "@/apps/AppRouter/const";

import { IStatement } from "@/api/interfaces/responses";

import {
  STATEMENT_STATUS,
  STATEMENT_STATUS_COLORS,
  STATEMENT_STATUS_NAMES,
  STATEMENT_TYPES
} from "../const";
import {
  CELL_TYPES
} from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import {
  THEME_MODAL,
  VARIANT_MODAL
} from "@/apps/Modals/const";

import { isUndefined } from "@/utils/helpers";

import { EDITING_CELL } from "../models";

import { useStores } from "@/hooks";

import {
  buttonDotsStyle,
  cellStatusStyle,
  nameAuthorCellsStyle,
  textOverflowStyle
} from "../style";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";
import { SWR_KEYS } from "@/const";

/**
 * @component StatusCells
 * * компонент ячейки таблицы статуса заявки
 * @param
 */
export const StatusCells = (_: string, storage: IStatement): JSX.Element => {
  const theme = useTheme();
  const isExistStatus = !isUndefined(
    STATEMENT_STATUS_COLORS[storage.status]?.text
  );
  const backgroundColor =
    STATEMENT_STATUS_COLORS[storage.status]?.background ??
    theme.palette.blackAndWhite.white;
  const textColor =
    STATEMENT_STATUS_COLORS[storage.status]?.text ??
    theme.palette.blackAndWhite.white;

  return (
    <>
      {isExistStatus ? (
        <Box
          sx={cellStatusStyle(theme, backgroundColor, textColor)}>
          {STATEMENT_STATUS[storage.status]}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

/**
 * @component NumberCells
 * * компонент ячейки таблицы номера заявки
 * @param
 */
export const NumberCells = (_: string, storage: IStatement): JSX.Element => {
  return (
    <Box>
      <Typography fontSize={14}>{storage.id}</Typography>
    </Box>
  );
};

/**
 * @component TypeCells
 * * компонент ячейки таблицы типа заявки
 * @param
 */
export const TypeCells = (_: string, storage: IStatement): JSX.Element => {
  return (
    <Box
      display={"flex"}
      sx={{
        maxHeight: "60px"
      }}
    >
      <Typography
        fontSize={14}
        title={storage.name}
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: "3",
          WebkitBoxOrient: "vertical",
          overflow: "hidden"
        }}
      >
        {storage.name}
      </Typography>
    </Box>
  );
};

/**
 * @component NameAuthorCells
 * * компонент ячейки таблицы имени автора заявки
 * @param
 */
export const NameAuthorCells = (
  _: string,
  storage: IStatement
): JSX.Element => {
  const { applicant } = storage;
  const fullName = applicant?.name ?? "-";
  const firstNLastName = applicant?.first_name && applicant?.last_name
    ? `${applicant.first_name} ${applicant.last_name}`
    : undefined

  return (
    <Box sx={nameAuthorCellsStyle()}>
      <Typography
        sx={textOverflowStyle()}
        fontSize={14}
        title={`${fullName}`}
      >
        {`${firstNLastName ? firstNLastName : fullName}`}
      </Typography>
    </Box>
  );
};

/**
 * @component DateCells
 * * компонент ячейки таблицы имени автора заявки
 * @param
 */
export const DateCells = (_: string, storage: IStatement): JSX.Element => {
  const date = dayjs(storage.registration_date).format("DD.MM.YYYY");
  return (
    <Box>
      <Typography fontSize={14}>{date}</Typography>
    </Box>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCells = (_: string, storage: IStatement): JSX.Element => {
  const [searchParam, setSearchParam] = useSearchParams();
  const {
    api,
    modalStore,
    rightSidebarStore,
    userStore,
    swrStore
  } = useStores();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);
  const status = storage.status;

  const mutateList = swrStore.mutators[SWR_KEYS.getStatements()];

  const isEdit = userStore.isPermission(
    PERMISSIONS.registrationStatement,
    PERMISSION_CRUD.change
  );

  const isCopy = userStore.isPermission(
    PERMISSIONS.registrationStatement,
    PERMISSION_CRUD.add
  );

  const isDelete = userStore.isPermission(
    PERMISSIONS.registrationStatement,
    PERMISSION_CRUD.delete
  );

  const isHidden =
    status === STATEMENT_STATUS_NAMES.review ||
    status === STATEMENT_STATUS_NAMES.refinement;

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const handleDeleteStatement = (resolve, reject) => {
    api.statement
      .deleteStatementById(storage.id)
      .then(() => {
        if (String(storage.id) === searchParam.get("statement_id")) {
          setSearchParam("");
          rightSidebarStore.close();
        }
        resolve();
      })
      .catch(reject).finally(() => {
      mutateList && mutateList();
      setIsOpen(null);
    });
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const type = storage.type;
    const statement_type = (() => {
      if (type && type in STATEMENT_TYPES) {
        return STATEMENT_TYPES[type];
      }
      return "Неопознанный тип";
    })();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.statement,
      status: storage.status,
      id: storage.id,
      statement_type,
      handleDelete: handleDeleteStatement
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };

  const handleClickCopy = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.ADD_STATEMENT, {
      onSubmitType: STATEMET_TYPES_SUBMIT.copy,
      id: storage.id,
      title: "Копировать заявку"
    });
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate(`/${ROUTES.statements}/${storage.id}/${ROUTES.editStatement}`);
  };

  const renderMenuItem = () => {
    if (
      storage.status === STATEMENT_STATUS_NAMES.accepted || storage.status === STATEMENT_STATUS_NAMES.rejected
    ) {
      return (
        <MenuItem onClick={handleClickCopy}>
          <CopyIcon/>

          {EDITING_CELL.duplicate}
        </MenuItem>
      );
    } else {
      return (
        <>
          {isEdit && (
            <StyledMenuItem onClick={handleClickEdit}>
              <EditIcon/>

              {EDITING_CELL.editing}
            </StyledMenuItem>
          )}

          {isCopy && (
            <StyledMenuItem onClick={handleClickCopy}>
              <CopyIcon/>

              {EDITING_CELL.duplicate}
            </StyledMenuItem>
          )}

          {isHidden || !isDelete ? null : (
            <>
              {!isEdit && !isCopy ? null : <Divider/>}
              <StyledMenuItem onClick={handleClickDelete}>
                <DeleteIcon color="error"/>

                <Typography
                  color="error">{EDITING_CELL.delete}</Typography>
              </StyledMenuItem>
            </>
          )}
        </>
      );
    }
  };

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()}
                  onClick={handleToggle}>
        <MoreVertIcon/>
      </IconButton>

      <Menu anchorEl={isOpen} open={open}
            onClose={handleClose}>
        {renderMenuItem()}
      </Menu>
    </Box>
  );
};
