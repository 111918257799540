
import { IFilter, IValue, TRangeDateFormatted } from "@/interfaces";

import { STATEMENT_STATUS_INPUT } from "../const";
import { FiltersType } from "@/components/Filters/interfaces";

export const getFilters = (
  dateRange: TRangeDateFormatted,
  users: IValue[],
  handleScroll: (event: React.UIEvent<HTMLElement, UIEvent>) => void,
  statementTypeValue?: IValue,
  executorValue?: IValue
): IFilter[] => {
  return [
    {
      name: "status",
      label: "Статус",
      type: FiltersType.autoComplete,
      selectedValue: statementTypeValue,
      options: STATEMENT_STATUS_INPUT,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
    {
      name: "applicant",
      label: "Заявитель",
      type: FiltersType.autoComplete,
      selectedValue: executorValue,
      options: users,
      value: "",
      searchKey: "search_applicant",
      handleScroll: handleScroll,
      style: { minWidth: "260px",flexGrow: 1 },
    },
    {
      name: "date_range",
      label: "Дата регистрации",
      type: FiltersType.datesRange,
      selectedValue: dateRange,
      value: "",
    },
  ];
};
