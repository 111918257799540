import React from "react";
import { Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider, } from "@mui/material";
import { FIELDS_NAME, IFormik } from "../const";
import { useStores } from "@/hooks";
import {
  LocationAutoComplete,
  PlotAutoComplete
} from "@/components/Autocompletes";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import { IAgriculturalLabourLocation, IPlot } from "@/api/interfaces/responses";
import { YearDatePicker } from "@/components/Fields";

const DownloadSeedArrivalJournalForm = () => {
  const { modalStore } = useStores();
  const formik = useFormikContext<IFormik>();

  const handleChangeLocation = (payload: IAgriculturalLabourLocation) => {
    void formik.setFieldValue(FIELDS_NAME.LOCATION, payload);
  };
  
  const handleLocationClear = () => {
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
  };

  const handlePlotClear = () => {
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
  };

  const handleChangePlot = (payload: IPlot) => {
    void formik.setFieldValue(FIELDS_NAME.PLOT, payload);
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
  };

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <PlotAutoComplete
          name={FIELDS_NAME.PLOT}
          handleChange={handleChangePlot}
          onClear={handlePlotClear}
          required
        />
        <LocationAutoComplete
          name={FIELDS_NAME.LOCATION}
          handleChange={handleChangeLocation}
          q={{ plot: formik.values.plot?.id }}
          onClear={handleLocationClear}
          disabled={!formik.values.plot}
        />
        {/* <RangeDatePickerField name={FIELDS_NAME.YEAR}
          label="Дата (период от и до)"
          fullWidth={true} 
        /> */}
        <YearDatePicker 
          name={FIELDS_NAME.YEAR}
          fullWidth={true} 
          required 
          label="Год" 
        />
      </Stack>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red"
          onClick={() => modalStore.close()}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<FileDownloadOutlinedIcon />}
          disabled={formik.isSubmitting}
        >
          Скачать
        </Button>
      </Box>
    </Form>
  );
};

export default DownloadSeedArrivalJournalForm;
