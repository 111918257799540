import React from "react";
import { observer } from "mobx-react";
import { Dayjs } from "dayjs";
import {
  Form,
  useFormikContext,
  FastField,
  FieldArray
} from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  FormControl,
  FormLabel
} from "@mui/material";
import {
  WorkAutocomplete
} from "@/components/Autocompletes";
import {
  FIELDS_NAME,
  IFormik,
  ICultivarFieldWorkChemistry
} from "../../const";
import CheckIcon from "@mui/icons-material/Check";
import TechMapPlanAutocomplete
  from "@/components/Autocompletes/TechMapPlanAutocomplete";
import {
  IAgriculturalLabourWork,
  ITechMapPlan
} from "@/api/interfaces/responses";
import theme from "@/theme";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import {
  VARIANT_MODAL,
  THEME_MODAL
} from "@/apps/Modals/const";
import { CHEMISTRY_TYPES } from "@/const";
import { useStores } from "@/hooks";
import ChemistryItem
  from "@/pages/AddCultivarFieldWork/components/ChemistrtItem";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";

/**
 * Блок с полями работы плана использования земли
 */

interface IProps {
  id?: string;
  cultivar?: number;
  plot?: number;
}

const WorkForm: React.FC<IProps> = ({ id, cultivar, plot }) => {
  const formik = useFormikContext<IFormik>();
  const { modalStore, userStore } = useStores();
  const canAddCultivarFieldWorkChemistry = userStore.isPermission(PERMISSIONS.agriculturalLabourCultivarFieldWorkChemistry, PERMISSION_CRUD.add);


  const handleAddFertilizer = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CHEMISTRY, {
      theme: THEME_MODAL.W_555,
      type: CHEMISTRY_TYPES.FERTILIZER,
      title: "Добавить удобрения",
      handleSubmit: (payload: ICultivarFieldWorkChemistry) => {
        const fertilizers = formik.values.fertilizers;
        void formik.setFieldValue(FIELDS_NAME.FERTILIZERS, [...fertilizers, payload]);
      }
    });
  };
  const handleAddPesticide = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CHEMISTRY, {
      theme: THEME_MODAL.W_555,
      type: CHEMISTRY_TYPES.PESTICIDE,
      title: "Добавить защиту",
      handleSubmit: (payload: ICultivarFieldWorkChemistry) => {
        const pesticides = formik.values.pesticides;
        void formik.setFieldValue(FIELDS_NAME.PESTICIDES, [...pesticides, payload]);
      }
    });
  };

  const handleChangeWork = (child: IAgriculturalLabourWork) => {
    void formik.setFieldValue(FIELDS_NAME.WORK, child);
  };

  const handleWorkClear = () => {
    void formik.setFieldValue(FIELDS_NAME.WORK, "");
  };

  const handleChangeTechMap = (payload: ITechMapPlan) => {
    void formik.setFieldValue(FIELDS_NAME.TECH_MAP, payload);
    void formik.setFieldValue(FIELDS_NAME.WORK, "");
  };

  const handleTechMapClear = () => {
    void formik.setFieldValue(FIELDS_NAME.TECH_MAP, "");
    void formik.setFieldValue(FIELDS_NAME.WORK, "");
  };

  const handleChangeStartDate = (payload: Dayjs) => {
    void formik.setFieldValue(FIELDS_NAME.START_DATE, payload);
  };

  const handleChangeEndDate = (payload: Dayjs) => {
    void formik.setFieldValue(FIELDS_NAME.END_DATE, payload);
  };

  return (
    <Form noValidate>
      <Box p={3} sx={{ maxWidth: 750 }}>
        <Typography variant="h3" marginBottom="20px">
          Информация о работе
        </Typography>
        <Stack direction="column" gap="20px">
          <Box maxWidth="350px">
            <TechMapPlanAutocomplete
              name={FIELDS_NAME.TECH_MAP}
              handleChange={handleChangeTechMap}
              onClear={handleTechMapClear}
              fullWidth={false}
              q={{
                cultivar: cultivar,
                plot: plot
              }}
            />
          </Box>
          <Box maxWidth="350px">
            <WorkAutocomplete
              name={FIELDS_NAME.WORK}
              handleChange={handleChangeWork}
              disabled={!formik.values.tech_map}
              onClear={handleWorkClear}
              fullWidth={false}
              q={{ tech_map_plan: formik.values.tech_map?.id }}
              required={true}
            />
          </Box>
          <Box display="flex" alignItems="center"
               gap="20px">
            <Box maxWidth="250px">
              <FastField name={FIELDS_NAME.START_DATE}>
                {({ field, meta }) => (
                  <DatePicker
                    name={field.name}
                    value={field.value}
                    onChange={handleChangeStartDate}
                    label="Начало работы"
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        error: meta.touched && !!meta.error,
                        helperText:
                          meta.touched && meta.error,
                        onBlur: field.onBlur,
                      },
                    }}
                  />
                )}
              </FastField>
            </Box>
            <Box width="24px">
              <Divider
                sx={{ borderColor: theme.palette.blackAndWhite.gray }}/>
            </Box>
            <Box maxWidth="250px">
              <FastField name={FIELDS_NAME.END_DATE}>
                {({ field, meta }) => (
                  <DatePicker
                    name={field.name}
                    value={field.value}
                    onChange={handleChangeEndDate}
                    label="Завершение работы"
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        error: meta.touched && !!meta.error,
                        helperText:
                          meta.touched && meta.error,
                        onBlur: field.onBlur,
                      },
                    }}
                  />
                )}
              </FastField>
            </Box>
          </Box>
          {
            !id && canAddCultivarFieldWorkChemistry ? (<>
              <FieldArray
                name={FIELDS_NAME.FERTILIZERS}
                render={(helpers) => {
                  const values = helpers.form.values[helpers.name];
                  return (
                    <FormControl>
                      <FormLabel>
                        <Typography variant="h4">
                          Удобрения
                        </Typography>
                      </FormLabel>
                      {
                        values.map((el, index) => (
                          <ChemistryItem key={index}
                                         item={el}
                                         handleDelete={() => helpers.remove(index)}/>
                        ))
                      }
                    </FormControl>
                  );
                }}
              />
              <Button
                color={"blue"}
                size={"large"}
                startIcon={<AddOutlinedIcon/>}
                variant={"outlined"}
                onClick={handleAddFertilizer}
              >
                Добавить удобрение
              </Button>
            </>) : null
          }
          {
            !id && canAddCultivarFieldWorkChemistry ? (
              <>
                <FieldArray
                  name={FIELDS_NAME.PESTICIDES}
                  render={(helpers) => {
                    const values = helpers.form.values[helpers.name];
                    return (
                      <FormControl>
                        <FormLabel>
                          <Typography variant="h4">
                            Средства защиты
                          </Typography>
                        </FormLabel>
                        {
                          values.map((el, index) => (
                            <ChemistryItem key={index}
                                           item={el}
                                           handleDelete={() => helpers.remove(index)}/>
                          ))
                        }
                      </FormControl>
                    );
                  }}
                />
                <Button
                  color={"blue"}
                  size={"large"}
                  startIcon={<AddOutlinedIcon/>}
                  variant={"outlined"}
                  onClick={handleAddPesticide}
                >
                  Добавить средство защиты
                </Button>
              </>
            ) : null
          }
        </Stack>
        <Box display="flex" justifyContent="space-between"
             mt={5}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<CheckIcon/>}
            disabled={formik.isSubmitting}
          >
            Готово
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default observer(WorkForm);
